// src/firebaseTypes.ts
var ASSET_DESCRIPTIONS = {
  2: "Bygning, generelt",
  20: "Bygning, generelt",
  200: "Bygning, generelt",
  21: "Grunn og fundamenter",
  210: "Grunn og fundamenter",
  211: "Klargj\xF8ring av tomt",
  212: "Byggegrop",
  213: "Grunnforsterkning",
  214: "St\xF8ttekonstruksjoner",
  215: "Pelefundamentering",
  216: "Direkte fundamentering",
  217: "Drenering",
  219: "Andre deler av grunn og fundamenter",
  22: "B\xE6resystemer",
  220: "B\xE6resystemer",
  221: "Rammer",
  222: "S\xF8yler",
  223: "Bjelker",
  224: "Avstivede konstruksjoner",
  225: "Brannbeskyttelse av b\xE6rendekonstruksjoner",
  226: "Kledning og overflate",
  229: "Andre deler av b\xE6resystemer",
  23: "Yttervegger",
  230: "Yttervegger",
  231: "B\xE6rende yttervegger",
  232: "Ikke-b\xE6rende yttervegger",
  233: "Glassfasader",
  234: "Vinduer, d\xF8rer, porter",
  235: "Utvendig kledning og overflate.",
  236: "Innvendig overflate",
  237: "Solavskjerming",
  239: "Andre deler av yttervegger",
  24: "Innervegger",
  240: "Innervegger",
  241: "B\xE6rende innervegger",
  242: "Ikke-b\xE6rende innervegg.",
  243: "Systemvegger, glassfelt",
  245: "Skj\xF8rt",
  246: "Kledning og overflate",
  249: "Andre deler av innervegger",
  25: "Dekker",
  250: "Dekker",
  251: "Frittb\xE6rende dekker",
  252: "Gulv p\xE5 grunn",
  253: "Oppf\xF4ret gulv, p\xE5st\xF8p",
  254: "Gulvsystemer",
  255: "Gulvoverflate",
  256: "Faste himlinger og overflatebehandling",
  257: "Systemhimlinger",
  259: "Andre deler av dekker",
  26: "Yttertak",
  260: "Yttertak",
  261: "Prim\xE6rkonstruksjoner",
  262: "Taktekning",
  263: "Glasstak, overlys, takluker",
  264: "Takoppbygg",
  265: "Gesimser, takrenner og nedl\xF8p",
  266: "Himling og innvendig overflate",
  267: "Prefabrikerte takelementer",
  269: "Andre deler av yttertak",
  27: "Fast inventar",
  270: "Fast inventar",
  271: "Murte piper og ildsteder",
  272: "Monteringsferdige ildsteder",
  273: "Kj\xF8kkeninnredning",
  274: "Innredning og garnityr for v\xE5trom",
  275: "Skap og reoler",
  276: "Sittebenker, stolrader, bord",
  277: "Skilt og tavler",
  279: "Andre deler av fast inventar",
  28: "Trapper, balkonger m.m.",
  280: "Trapper, balkonger m.m.",
  281: "Innvendige trapper",
  282: "Utvendige trapper",
  283: "Ramper",
  284: "Balkonger og verandaer",
  285: "tribuner og amfier",
  286: "baldakiner og skjermtak",
  287: "Andre rekkverk, h\xE5ndlister og fendere",
  289: "Andre deler av trapper, balkonger m.m.",
  3: "Generelt vedr. VVS- installasjoner",
  30: "Generelt vedr. VVS- installasjoner",
  300: "Generelt vedr. VVS- installasjoner",
  31: "Sanit\xE6r",
  310: "Sanit\xE6ranlegg",
  311: "Bunnledninger for sanit\xE6rinstallasjon",
  32: "Varme",
  320: "Varmeanlegg",
  33: "Brannslokking",
  330: "Brannslokking",
  331: "Installasjon for manuell brannslokking med vann",
  332: "Installasjon for brannslokking med sprinkler",
  333: "Installasjon for brannslokking med vannt\xE5ke",
  334: "Installasjon for brannslokking med pulver",
  335: "Installasjon for brannslokkig med inertgass",
  339: "Andre installasjoner for brannslokking",
  34: "Gass og trykkluft",
  340: "Gass og trykkluft",
  341: "Installasjon til gass for bygningsdrift",
  342: "Installasjon til gass for virksomhet i ferdig bygg",
  343: "Installasjon til medisinske gasser",
  345: "Installasjon til trykkluft for virksomhet i ferdig bygg",
  346: "Installasjon til medisinsk trykkluft",
  347: "Vakuumsystemer",
  349: "Andre installasjoner til gass- og trykkluft",
  35: "Prosesskj\xF8ling",
  350: "Prosesskj\xF8ling",
  351: "Kj\xF8leromsystemer",
  352: "Fryseromsystemer",
  353: "Kj\xF8lesystemer for virksomhet",
  354: "Kj\xF8lesystemer for produksjon",
  355: "Kuldesystemer for innend\xF8rs idrettsbaner",
  359: "Annen installasjon for kulde- og kj\xF8lesystemer",
  36: "Luftbehandling",
  360: "Luftbehandling",
  37: "Komfortkj\xF8ling",
  370: "Komfortkj\xF8leanlegg",
  38: "Vannbehandling",
  380: "Vannbehandling",
  381: "Systemer for rensing av forbruksvann",
  382: "Systemer for rensing av avl\xF8psvann",
  383: "Systemer for rensing av vann til sv\xF8mmebasseng",
  386: "Innend\xF8rs fontener og springvann",
  389: "Annen vannbehandling",
  39: "Andre VVS-installasjoner",
  390: "Andre VVS-installasjoner",
  4: "Elkraft, generelt",
  40: "Elkraft, generelt",
  400: "Elkraft, generelt",
  41: "Basisinstallasjon for elkraft",
  410: "Basisinstallasjon for elkraft",
  411: "Systemer for kabelf\xF8ring",
  412: "Systemer for jording",
  413: "Systemer for lynvern",
  419: "Andre basisinstallasjoner for elkraft",
  42: "H\xF8yspent forsyning",
  420: "H\xF8yspent forsyning",
  421: "Fordelingssystemer",
  422: "Nettstasjoner",
  429: "Andre deler for h\xF8yspent forsyning",
  43: "Lavspent forsyning",
  430: "Lavspent forsyning",
  431: "System for elkraftinntak",
  432: "System for hovedfordeling",
  433: "Elkraftfordeling til alminnelig forbruk",
  434: "Elkraftfordeling til driftstekniske installasjoner",
  435: "Elkraftfordeling til virksomhet",
  439: "Annen lavspent forsyning",
  44: "Lys",
  440: "Lys",
  442: "Belysningsutstyr",
  443: "N\xF8dlysutstyr",
  449: "Andre deler for installasjoner til lys",
  45: "Elvarme",
  450: "Elvarme",
  46: "Reservekraft",
  460: "Reservekraft",
  461: "Elkraftaggregater",
  462: "Avbruddsfri kraftforsyning",
  463: "Akkumulatoranlegg",
  469: "Andre deler for reservekraftforsyning",
  47: "Lokal kraftproduksjon",
  470: "Lokal kraftproduksjon",
  471: "Solcellesystem",
  472: "Vindm\xF8llesystem",
  473: "Kogenerering",
  49: "Andre elkraftinstallasjoner",
  490: "Andre elkraftinstallasjoner",
  5: "Tele og automatisering, generelt",
  50: "Tele og automatisering, generelt",
  500: "Tele og automatisering, generelt",
  51: "Basisinstallasjon for tele og automatisering",
  510: "Basisinstallasjon for tele og automatisering",
  511: "Systemer for kabelf\xF8rin",
  512: "Jording",
  514: "Inntakskabler for teleanlegg",
  515: "Telefordelinger",
  519: "Andre generelle anlegg for tele og automatisering",
  52: "Integrert kommunikasjon",
  520: "Integrert kommunikasjon",
  529: "Teknisk spredenett (Andre installasjoner for integrert kommunikasjon)",
  53: "Telefoni og persons\xF8kning",
  530: "Telefoni og persons\xF8kning",
  532: "Systemer for telefoni",
  534: "Systemer for porttelefoner",
  535: "Systemer for h\xF8yttalende hustelefoner",
  536: "Systemer for persons\xF8kning",
  539: "Andre installasjoner for telefoni og persons\xF8kning",
  54: "Alarm- og signalsystemer",
  540: "Alarm- og signalsystemer",
  542: "Brannalarm",
  543: "Adgangskontroll, innbrudds- og overfallsalarm",
  544: "Pasientsignal",
  545: "Uranlegg og tidsregistrering",
  547: "D\xF8rsystem",
  549: "Andre installasjoner for alarm og signal",
  55: "Lyd- og bildesystemer",
  550: "Lyd- og bildesystemer",
  552: "Fellesantenne",
  553: "Internfjernsyn",
  554: "Lyddistribusjonsanlegg",
  555: "Lydanlegg",
  556: "Bilde og AV-utstyr",
  559: "Annen installasjon for lyd og bilde",
  56: "Automatisering",
  560: "Automatisering",
  562: "Sentral driftskontroll og automatisering",
  563: "Lokal automatisering",
  564: "Buss-systemer",
  565: "FDVUS: Administrativt system",
  569: "Andre installasjoner for automatisering",
  57: "Instrumentering",
  570: "Instrumentering",
  59: "Andre installasjoner for tele og automatisering",
  590: "Andre installasjoner for tele og automatisering",
  6: "Andre installasjoner, generelt",
  60: "Andre installasjoner, generelt",
  600: "Andre installasjoner, generelt",
  61: "Prefabrikerte rom",
  610: "Prefabrikerte rom",
  611: "Prefabrikerte kj\xF8lerom",
  612: "Prefabrikerte fryserom",
  613: "Prefabrikerte baderom",
  614: "Prefabrikerte skjermrom",
  615: "Prefabrikerte sjakter",
  619: "Andre prefabrikerte rom",
  62: "Person- og varetransport",
  620: "Person- og varetransport",
  621: "Heiser",
  622: "Rulletrapper",
  623: "Rulleb\xE5nd",
  624: "L\xF8ftebord",
  625: "Trappeheiser",
  626: "Kraner",
  627: "Fasade- og takvask",
  629: "Annen person- og varetransport",
  63: "Transportanlegg for sm\xE5varer m.v.",
  630: "Transportanlegg for sm\xE5varer m.v.",
  631: "Dokument og sm\xE5varetransport\xF8rer",
  632: "Transportanlegg for t\xF8rr og l\xF8s masse",
  639: "Andre anlegg for transport av sm\xE5varer m.v.",
  64: "Sceneteknisk utstyr",
  640: "Sceneteknisk utstyr",
  65: "Avfall og st\xF8vsuging",
  650: "Avfall og st\xF8vsuging",
  651: "Utstyr for oppsamling og behandling av avfall",
  652: "Sentralst\xF8vsuger",
  653: "Pneumatisk s\xF8ppeltransport",
  659: "Andre installasjoner for avfall og st\xF8vsugning",
  66: "Fastmontert spesialutrustning for virksomhet",
  660: "Fastmontert spesialutrustning for virksomhet",
  67: "L\xF8s spesialutrustning for virksomhet",
  670: "L\xF8s spesialutrustning for virksomhet",
  69: "Andre tekniske installasjoner",
  690: "Andre tekniske installasjoner",
  7: "Utend\xF8rs, generelt",
  70: "Utend\xF8rs, generelt",
  700: "Utend\xF8rs, generelt",
  71: "Bearbeidet terreng",
  710: "Bearbeidet terreng",
  711: "Grovplanert terreng",
  712: "Drenering",
  713: "Forsterket grunn",
  714: "Gr\xF8fter og groper for tekniske installasjoner",
  715: "Sikring av bergrom/fjellhall/tunnel",
  719: "Annen terrengbearbeiding",
  72: "Utend\xF8rs konstruksjoner",
  720: "Utend\xF8rs konstruksjoner",
  721: "St\xF8ttemurer og andre murer",
  722: "Trapper og ramper i terreng",
  723: "Frittst\xE5ende skjermtak, leskur m.v.",
  724: "Sv\xF8mmebassenger m.v.",
  725: "Gjerder, porter og bommer",
  726: "Kanaler og kulverter for tekniske installasjoner",
  727: "Kummer for tekniske installasjoner",
  729: "Andre utend\xF8rs konstruksjoner",
  73: "Utend\xF8rsr\xF8ranlegg",
  730: "Utend\xF8rsr\xF8ranlegg",
  731: "Utvend\xF8rs VA",
  732: "Utend\xF8rs varme",
  733: "Utend\xF8rs brannslokking",
  734: "Utend\xF8rs gassinstallasjon",
  735: "Utend\xF8rs kj\xF8ling for idrettsbaner",
  736: "Utend\xF8rs luftbehandlingsanlegg",
  737: "Utend\xF8rs forsyningsanlegg for termisk energi",
  738: "Utend\xF8rs fontener og springvann",
  739: "Annen utend\xF8rs VVS",
  74: "Utend\xF8rs elkraft",
  740: "Utend\xF8rs elkraft",
  742: "Utend\xF8rs h\xF8yspent forsyning",
  743: "Utend\xF8rs lavspent forsyning",
  744: "Utend\xF8rs lys",
  745: "Utend\xF8rs elvarme",
  746: "Utend\xF8rs reservekraft",
  749: "Annen utend\xF8rs elkraft",
  75: "Utend\xF8rs tele og automatisering",
  750: "Utend\xF8rs tele og automatisering",
  752: "Utend\xF8rs integrert kommunikasjon",
  753: "Utend\xF8rs telefoni og persons\xF8kning",
  754: "Utend\xF8rs alarm og signal",
  755: "Utend\xF8rs lyd og bilde",
  756: "Utend\xF8rs automatisering",
  759: "Andre installasjoner for utend\xF8rs tele og automatisering",
  76: "Veger og plasser",
  760: "Veger og plasser",
  761: "Veier",
  762: "Plasser",
  763: "Skilter",
  764: "Perimetersikring (Sikkerhetsrekkverk, avvisere m.v.)",
  769: "Andre veier og plasser",
  77: "Parker og hager",
  770: "Parker og hager",
  773: "Utstyr",
  779: "Andre deler for parker og hager",
  79: "Andre utend\xF8rs anlegg",
  790: "Andre utend\xF8rs anlegg"
};
var TFM_SYSTEM_NUMBERS = [
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "76",
  "77",
  "79",
  "200",
  "210",
  "211",
  "212",
  "213",
  "214",
  "215",
  "216",
  "217",
  "219",
  "220",
  "221",
  "222",
  "223",
  "224",
  "225",
  "226",
  "229",
  "230",
  "231",
  "232",
  "233",
  "234",
  "235",
  "236",
  "237",
  "239",
  "240",
  "241",
  "242",
  "243",
  "245",
  "246",
  "249",
  "250",
  "251",
  "252",
  "253",
  "254",
  "255",
  "256",
  "257",
  "259",
  "260",
  "261",
  "262",
  "263",
  "264",
  "265",
  "266",
  "267",
  "269",
  "270",
  "271",
  "272",
  "273",
  "274",
  "275",
  "276",
  "277",
  "279",
  "280",
  "281",
  "282",
  "283",
  "284",
  "285",
  "286",
  "287",
  "289",
  "300",
  "310",
  "311",
  "320",
  "330",
  "331",
  "332",
  "333",
  "334",
  "335",
  "339",
  "340",
  "341",
  "342",
  "343",
  "345",
  "346",
  "347",
  "349",
  "350",
  "351",
  "352",
  "353",
  "354",
  "355",
  "359",
  "360",
  "370",
  "380",
  "381",
  "382",
  "383",
  "386",
  "389",
  "390",
  "400",
  "410",
  "411",
  "412",
  "413",
  "419",
  "420",
  "421",
  "422",
  "429",
  "430",
  "431",
  "432",
  "433",
  "434",
  "435",
  "439",
  "440",
  "442",
  "443",
  "449",
  "450",
  "460",
  "461",
  "462",
  "463",
  "469",
  "470",
  "471",
  "472",
  "473",
  "490",
  "500",
  "510",
  "511",
  "512",
  "514",
  "515",
  "519",
  "520",
  "529",
  "530",
  "532",
  "534",
  "535",
  "536",
  "539",
  "540",
  "542",
  "543",
  "544",
  "545",
  "547",
  "549",
  "550",
  "552",
  "553",
  "554",
  "555",
  "556",
  "559",
  "560",
  "562",
  "563",
  "564",
  "565",
  "569",
  "570",
  "590",
  "600",
  "610",
  "611",
  "612",
  "613",
  "614",
  "615",
  "619",
  "620",
  "621",
  "622",
  "623",
  "624",
  "625",
  "626",
  "627",
  "629",
  "630",
  "631",
  "632",
  "639",
  "640",
  "650",
  "651",
  "652",
  "653",
  "659",
  "660",
  "670",
  "690",
  "700",
  "710",
  "711",
  "712",
  "713",
  "714",
  "715",
  "719",
  "720",
  "721",
  "722",
  "723",
  "724",
  "725",
  "726",
  "727",
  "729",
  "730",
  "731",
  "732",
  "733",
  "734",
  "735",
  "736",
  "737",
  "738",
  "739",
  "740",
  "742",
  "743",
  "744",
  "745",
  "746",
  "749",
  "750",
  "752",
  "753",
  "754",
  "755",
  "756",
  "759",
  "760",
  "761",
  "762",
  "763",
  "764",
  "769",
  "770",
  "773",
  "779",
  "790"
];
var tfm1SystemNumbers = TFM_SYSTEM_NUMBERS.filter(
  (tfm) => tfm.length == 1
);
var tfm2SystemNumbers = TFM_SYSTEM_NUMBERS.filter(
  (tfm) => tfm.length == 2
);
var tfm3SystemNumbers = TFM_SYSTEM_NUMBERS.filter(
  (tfm) => tfm.length == 3
);
var SEVERITY_TITLE = {
  ERROR: "Feil",
  WARNING: "Advarsel",
  INFO: "Info"
};
var BUILDING_CATEGORIES = [
  "KINDERGARTEN",
  "APARTMENT_BUILDING",
  "BUSINESS_BUILDING",
  "HOTEL",
  "SPORT_BUILDING",
  "OFFICE_BUILDING",
  "CULTURE_BUILDING",
  "WORKSHOP",
  "SCHOOL_BUILDING",
  "SMALL_HOUSE",
  "NURSING_HOME",
  "HOSPITAL",
  "UNIVERSITY"
];
var BUILDING_TYPES = [
  "KINDERGARTEN",
  "APARTMENT_BUILDING",
  "SHOP_WITH_COLD_STORAGE",
  "GROCERY_STORE",
  "BUSINESS_BUILDING",
  "HOTEL",
  "SPORT_BUILDING",
  "OFFICE_BUILDING",
  "CULTURE_BUILDING",
  "WORKSHOP",
  "SCHOOL_BUILDING",
  "SMALL_HOUSE",
  "NURSING_HOME",
  "HOSPITAL",
  "UNIVERSITY"
];
var PERIOD_VALUES = [
  "WEEKDAYS",
  "WEEKENDS",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY"
];
var DAYS = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY"
];
var DAYS_SUNDAY_FIRST = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY"
];

// src/utils.ts
import dayjs from "@properate/dayjs";
import Holidays from "date-holidays";

// src/unit.ts
var UNIT_PCT_AKT = "%-akt";
var UNIT_PCT_NORM = "%-norm";
function isSpecialPctUnit(unit) {
  return unit === UNIT_PCT_AKT || unit === UNIT_PCT_NORM;
}
function formatUnit(unit = "") {
  return isSpecialPctUnit(unit) ? "%" : unit;
}
function getUnitWithDerivedUnits(unit) {
  const possibleUnits = DERIVED_UNITS[unit];
  return [
    { value: unit, label: unit },
    ...possibleUnits ? Object.keys(possibleUnits).map((unit2) => ({
      value: unit2,
      label: unit2
    })) : []
  ];
}
function convertPropsToUnit(object, keysToConvert, conversionFn) {
  return Object.entries(object).filter(
    (entry) => keysToConvert.includes(entry[0])
  ).reduce(
    (convertedEntryAcc, [key, value]) => {
      convertedEntryAcc[key] = conversionFn(value);
      return convertedEntryAcc;
    },
    {}
  );
}
function convertElementPropsToUnit(elements, { from, to }, keysToConvert = Array.isArray(elements) ? elements.length > 0 ? Object.keys(elements[0]) : [] : Object.keys(elements)) {
  if (!from) {
    return elements;
  }
  if (isSpecialPctUnit(from)) {
    return Array.isArray(elements) ? elements.map((element) => ({
      ...element,
      ...convertPropsToUnit(element, keysToConvert, (value) => value * 100)
    })) : {
      ...elements,
      ...convertPropsToUnit(
        elements,
        keysToConvert,
        (value) => value * 100
      )
    };
  }
  if (from === to) {
    return elements;
  }
  const conversionFn = DERIVED_UNITS[from]?.[to]?.to;
  if (conversionFn) {
    return Array.isArray(elements) ? elements.map((element) => ({
      ...element,
      ...convertPropsToUnit(element, keysToConvert, conversionFn)
    })) : {
      ...elements,
      ...convertPropsToUnit(elements, keysToConvert, conversionFn)
    };
  }
  return elements;
}
var DERIVED_UNITS = {
  "\xB0C": {
    "\xB0F": {
      to: (x) => x * (9 / 5) + 32,
      from: (x) => (x - 32) * (5 / 9)
    },
    K: { to: (x) => x + 273.15, from: (x) => x - 273.15 }
  },
  K: {
    "\xB0C": { to: (x) => x - 273.15, from: (x) => x + 273.15 },
    "\xB0F": {
      to: (x) => (x - 273.15) * (9 / 5) + 32,
      from: (x) => (x - 32) * (5 / 9) + 273.15
    }
  },
  "\xB0F": {
    "\xB0C": { to: (x) => (x - 32) * (5 / 9), from: (x) => x * (9 / 5) + 32 },
    K: {
      to: (x) => (x - 32) * (5 / 9) + 273.15,
      from: (x) => (x - 273.15) * (9 / 5) + 32
    }
  },
  Pa: {
    kPa: { to: (x) => x / 1e3, from: (x) => x * 1e3 },
    Bar: { to: (x) => x * 1e-5, from: (x) => x / 1e-5 },
    mmH2O: {
      to: (x) => x * 0.101972,
      from: (x) => x / 0.101972
    },
    atm: { to: (x) => x * 1e-5, from: (x) => x / 1e-5 }
  },
  Bar: {
    atm: { to: (x) => x, from: (x) => x }
  },
  "m\xB3/s": {
    "m\xB3/h": { to: (x) => x * 3600, from: (x) => x / 3600 },
    "l/s": { to: (x) => x * 1e3, from: (x) => x / 1e3 },
    "l/h": {
      to: (x) => x * (1e3 * 3600),
      from: (x) => x / (1e3 / 3600)
    }
  },
  "m\xB3/h": {
    "m\xB3/s": { to: (x) => x / 3600, from: (x) => x * 3600 },
    "l/s": { to: (x) => x / 3.6, from: (x) => x * 3.6 },
    "l/h": {
      to: (x) => x * 1e3,
      from: (x) => x / 1e3
    }
  },
  "m3/s": {
    "m3/h": { to: (x) => x * 3600, from: (x) => x / 3600 },
    "l/s": { to: (x) => x * 1e3, from: (x) => x / 1e3 },
    "l/h": {
      to: (x) => x * (1e3 * 3600),
      from: (x) => x / (1e3 / 3600)
    }
  },
  "m3/h": {
    "m3/s": { to: (x) => x / 3600, from: (x) => x * 3600 },
    "l/s": { to: (x) => x / 3.6, from: (x) => x * 3.6 },
    "l/h": {
      to: (x) => x * 1e3,
      from: (x) => x / 1e3
    }
  },
  ppm: {
    ppb: { to: (x) => x * 1e3, from: (x) => x / 1e3 }
  },
  ppb: {
    ppm: { to: (x) => x / 1e3, from: (x) => x * 1e3 }
  },
  kW: {
    W: { to: (x) => x * 1e3, from: (x) => x / 1e3 },
    MW: { to: (x) => x / 1e3, from: (x) => x * 1e3 },
    GW: { to: (x) => x / 1e6, from: (x) => x * 1e6 }
  },
  kWh: {
    Wh: { to: (x) => x * 1e3, from: (x) => x / 1e3 },
    MWh: { to: (x) => x / 1e3, from: (x) => x * 1e3 },
    GWh: { to: (x) => x / 1e6, from: (x) => x * 1e6 }
  },
  A: {
    mA: { to: (x) => x * 1e3, from: (x) => x / 1e3 }
  },
  V: {
    mV: { to: (x) => x * 1e3, from: (x) => x / 1e3 }
  },
  m: {
    mm: { to: (x) => x * 1e3, from: (x) => x / 1e3 },
    km: { to: (x) => x / 1e3, from: (x) => x * 1e3 }
  },
  sec: {
    min: {
      to: (x) => x / 60,
      from: (x) => x * 60
    },
    hour: {
      to: (x) => x / 3600,
      from: (x) => x * 3600
    }
  },
  "m/s": {
    "km/h": { to: (x) => x * 3.6, from: (x) => x / 3.6 }
  }
};

// src/alarms/types.ts
import { z } from "zod";
var FilterType = z.enum([
  "energy",
  "weather",
  "humidity",
  "temperature",
  "motion",
  "gass",
  "technical",
  "ventilation",
  "heating",
  "cooling",
  "sanitary",
  "building"
]);
var AlarmEventState = z.enum(["ALARM", "RESOLVED"]);
var AlarmSeverity = z.enum(["ERROR", "WARNING", "INFO", "CRITICAL"]);
var ProperateEvent = z.object({
  id: z.string().optional(),
  buildingId: z.number(),
  alarmId: z.string(),
  date: z.date(),
  dateCreated: z.number(),
  state: AlarmEventState,
  title: z.string(),
  severity: AlarmSeverity,
  name: z.string(),
  description: z.string(),
  asset: z.string(),
  value: z.string().optional(),
  comparisonValue: z.string().optional(),
  rule: z.string().optional(),
  sentAlertAt: z.date().optional()
});
var AlarmEventWithId = ProperateEvent.extend({
  snapshotId: z.string()
});
var TimeSpanObject = z.object({
  start: z.number(),
  end: z.number()
});
var DayType = z.enum([
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY"
]);
var Period = z.object({
  day: DayType,
  start: z.string(),
  end: z.string()
});
var TimeseriesType = z.object({
  ids: z.number().array(),
  name: z.string(),
  unit: z.string().optional(),
  color: z.string().optional(),
  dashed: z.boolean().optional(),
  show: z.function().args(z.date()).returns(z.boolean()).optional(),
  location: z.string().optional(),
  schedule: z.object({ interval: z.string().optional(), hour: z.string().optional() }).optional()
});
var AlarmType = z.enum([
  "ROOM_TEMPERATURE_MAX",
  "ROOM_TEMPERATURE_MIN",
  "VOC_MAX",
  "CO2_MAX",
  "EFFECT_GUARD",
  "EPRED_MAX",
  "EPRED_MIN",
  "CONSUMPTION_GUARD",
  "HUMIDITY_ALERT",
  "SET_POINT_ALERT",
  "NO_DATAPOINTS",
  "CUSTOM_MAX",
  "CUSTOM_MIN",
  "CUSTOM_TIMESERIES_MAX",
  "CUSTOM_TIMESERIES_MIN",
  "LOCKED_THIRD_PARTY_ALARM",
  "THIRD_PARTY_ALARM"
]);
var PhoneOrEmail = z.union([
  z.object({
    email: z.string(),
    phone: z.string().optional()
  }),
  z.object({
    email: z.string().optional(),
    phone: z.string()
  })
]);
var AlarmSubscription = z.object({
  namesForContact: z.record(z.string(), z.string()).optional(),
  mutedContacts: PhoneOrEmail.array().optional(),
  createdBy: z.string().optional(),
  emails: z.string().array().optional(),
  phones: z.string().array().optional()
});
var AlarmRuleSource = /* @__PURE__ */ ((AlarmRuleSource2) => {
  AlarmRuleSource2["BacNet"] = "BACnet";
  AlarmRuleSource2["User"] = "User";
  return AlarmRuleSource2;
})(AlarmRuleSource || {});
var ThirdPartyAlarmVisualTimeseriesType = /* @__PURE__ */ ((ThirdPartyAlarmVisualTimeseriesType2) => {
  ThirdPartyAlarmVisualTimeseriesType2["High"] = "high_lim";
  ThirdPartyAlarmVisualTimeseriesType2["Value"] = "val";
  ThirdPartyAlarmVisualTimeseriesType2["Low"] = "low_lim";
  return ThirdPartyAlarmVisualTimeseriesType2;
})(ThirdPartyAlarmVisualTimeseriesType || {});
var ThirdPartyAlarmVisualTimeseries = z.object({
  label: z.nativeEnum(ThirdPartyAlarmVisualTimeseriesType),
  value: z.number()
});
var AlarmRuleTypeName = /* @__PURE__ */ ((AlarmRuleTypeName2) => {
  AlarmRuleTypeName2["CompareTimeseries"] = "CompareTimeseries";
  AlarmRuleTypeName2["CompareTimeseriesPredictedEnergyConsumption"] = "CompareTimeseriesPredictedEnergyConsumption";
  AlarmRuleTypeName2["TimeseriesThreshold"] = "TimeseriesThreshold";
  AlarmRuleTypeName2["TimeseriesThresholdRoomTemp"] = "TimeseriesThresholdRoomTemp";
  AlarmRuleTypeName2["TimeseriesThresholdCO2"] = "TimeseriesThresholdCO2";
  AlarmRuleTypeName2["TimeseriesThresholdVOC"] = "TimeseriesThresholdVOC";
  AlarmRuleTypeName2["TimeseriesThresholdEffectGuard"] = "TimeseriesThresholdEffectGuard";
  AlarmRuleTypeName2["LeakProtection"] = "LeakProtection";
  AlarmRuleTypeName2["MissingData"] = "MissingData";
  AlarmRuleTypeName2["ThirdPartyAlarm"] = "ThirdPartyAlarm";
  AlarmRuleTypeName2["LockedThirdPartyAlarm"] = "LockedThirdPartyAlarm";
  return AlarmRuleTypeName2;
})(AlarmRuleTypeName || {});
var EffectGuardTenantType = /* @__PURE__ */ ((EffectGuardTenantType2) => {
  EffectGuardTenantType2["SingleTenant"] = "SingleTenant";
  EffectGuardTenantType2["EntireBuilding"] = "EntireBuilding";
  return EffectGuardTenantType2;
})(EffectGuardTenantType || {});
var AlarmSchedule = z.object({
  interval: z.string().or(z.literal("5m")),
  hour: z.string().optional()
});
var ComparisonTimeseries = z.object({
  ids: z.number().array(),
  // alltid bare 1
  name: z.string().optional(),
  // tidsserie-name
  buffer: z.number().optional(),
  schedule: AlarmSchedule.optional()
  // hentes fra tidsserie.metadata
});
var Alarm = z.object({
  extraAlarmRuleFields: z.object({
    updatedAt: z.date().optional(),
    createdByName: z.string().optional(),
    updatedBy: z.object({
      name: z.string().optional(),
      email: z.string().optional()
    }).optional(),
    priority: z.number().optional(),
    source: z.nativeEnum(AlarmRuleSource).optional(),
    dataSetId: z.number().optional(),
    ackRequired: z.boolean().optional(),
    visualTimeseries: ThirdPartyAlarmVisualTimeseries.array().optional(),
    type: z.nativeEnum(AlarmRuleTypeName),
    effectGuardTenantType: z.nativeEnum(EffectGuardTenantType),
    subBuildingId: z.number().optional()
  }),
  id: z.string().optional(),
  buildingId: z.number(),
  buildingAddress: z.string().optional(),
  date: z.date(),
  type: AlarmType,
  name: z.string().optional(),
  description: z.string().optional(),
  owner: z.string(),
  schedule: AlarmSchedule.optional(),
  // hentes fra tidsserie.metadata
  severity: AlarmSeverity,
  max: z.number().optional(),
  min: z.number().optional(),
  timeseries: TimeseriesType.optional(),
  compareWithTsMax: ComparisonTimeseries.optional(),
  compareWithTsMin: ComparisonTimeseries.optional(),
  alarmPeriods: Period.array(),
  inactivePeriods: TimeSpanObject.array().optional(),
  noAlarmsOnHolidays: z.boolean().optional(),
  category: FilterType.optional(),
  delay: z.number().optional(),
  subscriptions: AlarmSubscription.array().optional(),
  eventCount: z.number().optional(),
  activeEvent: AlarmEventWithId.optional(),
  maxTimeSinceLatestDatapoint: z.number().optional()
});
var AlarmWithId = Alarm.extend({
  snapshotId: z.string().optional()
});
var AlarmWithRequiredId = Alarm.extend({
  snapshotId: z.string()
});
var AlarmWithSnapshotId = Alarm.omit({ id: true }).extend({
  snapshotId: z.string().optional()
});
var OP = {
  "<": "Mindre enn",
  ">": "Mer enn",
  "=": "Er lik"
};
var SimplifiedAlarmPriority = /* @__PURE__ */ ((SimplifiedAlarmPriority2) => {
  SimplifiedAlarmPriority2["Low"] = "Low";
  SimplifiedAlarmPriority2["Medium"] = "Medium";
  SimplifiedAlarmPriority2["High"] = "High";
  SimplifiedAlarmPriority2["Critical"] = "Critical";
  return SimplifiedAlarmPriority2;
})(SimplifiedAlarmPriority || {});
var keysForAlarmPriority = {
  ["Low" /* Low */]: "alarm-details.common.levels.Low",
  ["Medium" /* Medium */]: "alarm-details.common.levels.Medium",
  ["High" /* High */]: "alarm-details.common.levels.High",
  ["Critical" /* Critical */]: "alarm-details.common.levels.Critical"
};
var AlertDay = /* @__PURE__ */ ((AlertDay2) => {
  AlertDay2["Monday"] = "MONDAY";
  AlertDay2["Tuesday"] = "TUESDAY";
  AlertDay2["Wednesday"] = "WEDNESDAY";
  AlertDay2["Thursday"] = "THURSDAY";
  AlertDay2["Friday"] = "FRIDAY";
  AlertDay2["Saturday"] = "SATURDAY";
  AlertDay2["Sunday"] = "SUNDAY";
  AlertDay2["Weekends"] = "WEEKENDS";
  AlertDay2["Weekdays"] = "WEEKDAYS";
  return AlertDay2;
})(AlertDay || {});
var keysForAlertDays = {
  ["MONDAY" /* Monday */]: "ui.monday-plural",
  ["TUESDAY" /* Tuesday */]: "ui.tuesday-plural",
  ["WEDNESDAY" /* Wednesday */]: "ui.wednesday-plural",
  ["THURSDAY" /* Thursday */]: "ui.thursday-plural",
  ["FRIDAY" /* Friday */]: "ui.friday-plural",
  ["SATURDAY" /* Saturday */]: "ui.saturday-plural",
  ["SUNDAY" /* Sunday */]: "ui.sunday-plural",
  ["WEEKDAYS" /* Weekdays */]: "ui.weekdays",
  ["WEEKENDS" /* Weekends */]: "ui.weekend-plural"
};
var AlertRecipient = z.intersection(
  z.object({
    name: z.string().optional(),
    muted: z.boolean().optional()
  }),
  PhoneOrEmail
);
var InactiveAlarmPeriod = z.object({
  start_date: z.number(),
  end_date: z.number()
});
var ActiveAlarmPeriod = z.intersection(
  z.object({
    day: z.nativeEnum(AlertDay)
  }),
  z.union([
    z.object({
      all_day: z.boolean(),
      start_time: z.unknown(),
      end_time: z.unknown()
    }),
    z.object({
      all_day: z.unknown(),
      start_time: z.string(),
      end_time: z.string()
    })
  ])
);
var OffsetDirection = /* @__PURE__ */ ((OffsetDirection2) => {
  OffsetDirection2["BaseGTCompare"] = "gt";
  OffsetDirection2["BaseLTCompare"] = "lt";
  return OffsetDirection2;
})(OffsetDirection || {});
var CommonAlarmRuleConditionFields = z.object({
  delay_ms: z.number(),
  alerts: z.object({
    recipients: AlertRecipient.array().optional()
  }).optional()
});
var CommonAlarmRuleOffsetFields = z.object({
  offset: z.number(),
  direction: z.nativeEnum(OffsetDirection)
});
var CommonAlarmRuleTypeSpecificSchedule = z.object({
  always_active: z.boolean(),
  active_periods: z.array(ActiveAlarmPeriod).optional(),
  inactive_periods: z.array(InactiveAlarmPeriod).optional(),
  active_on_holidays: z.boolean()
});
var CommonAlarmRuleTypeSpecificFields = z.object({
  base_timeseries_id: z.number(),
  schedule: CommonAlarmRuleTypeSpecificSchedule,
  evaluation: AlarmSchedule.optional().nullish()
});
var TimeseriesThresholdAlarmRule = z.object({
  type: z.enum([
    "TimeseriesThreshold" /* TimeseriesThreshold */,
    "TimeseriesThresholdRoomTemp" /* TimeseriesThresholdRoomTemp */,
    "TimeseriesThresholdCO2" /* TimeseriesThresholdCO2 */,
    "TimeseriesThresholdVOC" /* TimeseriesThresholdVOC */
  ]),
  condition: z.object({
    ...CommonAlarmRuleConditionFields.shape,
    type_specific: z.object({
      ...CommonAlarmRuleTypeSpecificFields.shape,
      ...CommonAlarmRuleOffsetFields.shape
    })
  })
});
var CompareTimeseriesAlarmRule = z.object({
  type: z.enum([
    "CompareTimeseries" /* CompareTimeseries */,
    "CompareTimeseriesPredictedEnergyConsumption" /* CompareTimeseriesPredictedEnergyConsumption */
  ]),
  condition: z.object({
    ...CommonAlarmRuleConditionFields.shape,
    type_specific: z.object({
      ...CommonAlarmRuleTypeSpecificFields.shape,
      ...CommonAlarmRuleOffsetFields.shape,
      comparison_timeseries_id: z.number()
    })
  })
});
var LeakProtectionAlarmRule = z.object({
  type: z.enum(["LeakProtection" /* LeakProtection */]),
  sub_building_id: z.number(),
  condition: z.object({
    ...CommonAlarmRuleConditionFields.shape,
    type_specific: CommonAlarmRuleTypeSpecificFields
  })
});
var EffectGuardAlarmRule = z.object({
  type: z.enum(["TimeseriesThresholdEffectGuard" /* TimeseriesThresholdEffectGuard */]),
  sub_building_id: z.number(),
  condition: z.object({
    ...CommonAlarmRuleConditionFields.shape,
    type_specific: z.object({
      ...CommonAlarmRuleTypeSpecificFields.shape,
      tenant_type: z.nativeEnum(EffectGuardTenantType)
    })
  })
});
var MissingDataAlarmRule = z.object({
  type: z.enum(["MissingData" /* MissingData */]),
  condition: z.object({
    ...CommonAlarmRuleConditionFields.shape,
    type_specific: CommonAlarmRuleTypeSpecificFields
  })
});
var ThirdPartyAlarmRule = z.object({
  type: z.enum([
    "LockedThirdPartyAlarm" /* LockedThirdPartyAlarm */,
    "ThirdPartyAlarm" /* ThirdPartyAlarm */
  ]),
  condition: z.object({
    ...CommonAlarmRuleConditionFields.shape,
    type_specific: z.object({
      ...CommonAlarmRuleTypeSpecificFields.shape,
      visual_timeseries: z.array(
        z.object({
          label: z.nativeEnum(ThirdPartyAlarmVisualTimeseriesType),
          value: z.number()
        })
      ),
      zero_is_error: z.boolean().optional()
    })
  })
});
var BaseAlarmRule = z.object({
  alarm_id: z.string(),
  building_id: z.number(),
  sub_building_id: z.number().optional(),
  data_set_id: z.number(),
  source: z.nativeEnum(AlarmRuleSource),
  name: z.string(),
  instruction: z.string().optional(),
  possible_alarm_states: z.string(),
  priority: z.number(),
  ack_required: z.boolean().optional(),
  ack_config: z.unknown().optional(),
  // TODO: Should be a definition of what requires ack if ack is more than a properate-ack
  created_by: z.object({
    name: z.string().optional(),
    email: z.string()
  }),
  created_at: z.number(),
  updated_by: z.object({
    name: z.string().optional(),
    email: z.string().optional()
  }),
  updated_at: z.number(),
  alarm_event_count: z.number().optional().nullish(),
  is_alarm_active: z.boolean().optional(),
  tfm_system_number: z.string().optional(),
  _firestoreObject: AlarmWithSnapshotId.optional()
});
var AlarmRule = z.intersection(
  BaseAlarmRule,
  z.union([
    TimeseriesThresholdAlarmRule,
    LeakProtectionAlarmRule,
    MissingDataAlarmRule,
    ThirdPartyAlarmRule,
    EffectGuardAlarmRule,
    CompareTimeseriesAlarmRule
  ])
);
var PartialAlarmRule = z.intersection(
  BaseAlarmRule.deepPartial(),
  z.union([
    TimeseriesThresholdAlarmRule.deepPartial(),
    LeakProtectionAlarmRule.deepPartial(),
    MissingDataAlarmRule.deepPartial(),
    ThirdPartyAlarmRule.deepPartial(),
    EffectGuardAlarmRule.deepPartial(),
    CompareTimeseriesAlarmRule.deepPartial()
  ])
);

// src/types.ts
var CogniteEventType = /* @__PURE__ */ ((CogniteEventType2) => {
  CogniteEventType2["WEB_NOTE"] = "note";
  CogniteEventType2["ALARM"] = "alarm_event";
  return CogniteEventType2;
})(CogniteEventType || {});

// src/alarms/alarms.ts
var MS_IN_MINUTE = 6e4;
function minutesToMs(minutes) {
  if (!minutes) {
    return 0;
  }
  return minutes * MS_IN_MINUTE;
}
function msToMinutes(ms) {
  if (!ms) {
    return 0;
  }
  return ms / MS_IN_MINUTE;
}
function getSimplifiedPriority(priority) {
  if (priority >= 192) {
    return "Low" /* Low */;
  }
  if (priority >= 128) {
    return "Medium" /* Medium */;
  }
  if (priority >= 64) {
    return "High" /* High */;
  }
  return "Critical" /* Critical */;
}
function getNumericPriority(priority) {
  switch (priority) {
    case "Critical" /* Critical */:
      return 63;
    case "High" /* High */:
      return 127;
    case "Medium" /* Medium */:
      return 191;
    case "Low" /* Low */:
    default:
      return 255;
  }
}
function getSimplifiedPriorityFromOldPriority(priority) {
  if (priority === "INFO") {
    return "Low" /* Low */;
  }
  if (priority === "WARNING") {
    return "Medium" /* Medium */;
  }
  return "High" /* High */;
}
function getNewAlarmNameFromOld(name) {
  if (name === "ROOM_TEMPERATURE_MAX" || name === "ROOM_TEMPERATURE_MIN") {
    return "TimeseriesThresholdRoomTemp" /* TimeseriesThresholdRoomTemp */;
  }
  if (name === "CUSTOM_MAX" || name === "CUSTOM_MIN") {
    return "TimeseriesThreshold" /* TimeseriesThreshold */;
  }
  if (name === "CUSTOM_TIMESERIES_MAX" || name === "CUSTOM_TIMESERIES_MIN") {
    return "CompareTimeseries" /* CompareTimeseries */;
  }
  if (name === "NO_DATAPOINTS") {
    return "MissingData" /* MissingData */;
  }
  if (name === "HUMIDITY_ALERT") {
    return "LeakProtection" /* LeakProtection */;
  }
  if (name === "VOC_MAX") {
    return "TimeseriesThresholdVOC" /* TimeseriesThresholdVOC */;
  }
  if (name === "CO2_MAX") {
    return "TimeseriesThresholdCO2" /* TimeseriesThresholdCO2 */;
  }
  if (name === "EFFECT_GUARD") {
    return "TimeseriesThresholdEffectGuard" /* TimeseriesThresholdEffectGuard */;
  }
  if (name === "EPRED_MAX" || name === "EPRED_MIN") {
    return "CompareTimeseriesPredictedEnergyConsumption" /* CompareTimeseriesPredictedEnergyConsumption */;
  }
  throw Error(`Alarm type not found: ${name}`);
}
var getUnitValue = (val) => {
  if (!val) {
    return {
      value: void 0,
      unit: void 0
    };
  }
  const value = val.match(/\d+/g);
  const unit = val.match(/[a-zA-Z]+/g);
  return {
    value: Number(value?.[0]?.replace(",", ".")),
    unit: unit?.[0]
  };
};
var getTriggerInfoForAlarmEvent = (alarm, event) => {
  if (alarm.type === "ROOM_TEMPERATURE_MAX" || alarm.type === "ROOM_TEMPERATURE_MIN") {
    return {
      type: "TimeseriesThresholdRoomTemp" /* TimeseriesThresholdRoomTemp */,
      value: event.value?.replace("\xB0C", "").replace(",", "."),
      threshold: alarm.type === "ROOM_TEMPERATURE_MAX" ? alarm.max : alarm.min,
      offset_direction: alarm.type === "ROOM_TEMPERATURE_MAX" ? "gt" /* BaseGTCompare */ : "lt" /* BaseLTCompare */,
      unit: "\xB0C"
    };
  }
  if (alarm.type === "CUSTOM_MAX" || alarm.type === "CUSTOM_MIN") {
    const { value, unit } = getUnitValue(event.value);
    return {
      type: "TimeseriesThreshold" /* TimeseriesThreshold */,
      value: value ? value.toString() : "",
      threshold: alarm.type === "CUSTOM_MAX" ? alarm.max : alarm.min,
      offset_direction: alarm.type === "CUSTOM_MAX" ? "gt" /* BaseGTCompare */ : "lt" /* BaseLTCompare */,
      unit: alarm.timeseries?.unit || (unit ? unit[0] : "")
    };
  }
  if (alarm.type === "CUSTOM_TIMESERIES_MAX" || alarm.type === "CUSTOM_TIMESERIES_MIN") {
    const { value, unit } = getUnitValue(event.value);
    return {
      type: "CompareTimeseries" /* CompareTimeseries */,
      value: value ? value.toString() : "",
      threshold: getUnitValue(event.comparisonValue)?.value,
      offset_direction: alarm.type === "CUSTOM_TIMESERIES_MAX" ? "gt" /* BaseGTCompare */ : "lt" /* BaseLTCompare */,
      unit: alarm.timeseries?.unit || (unit ? unit[0] : "")
    };
  }
  if (alarm.type === "NO_DATAPOINTS") {
    return {
      type: "MissingData" /* MissingData */,
      delay: alarm.maxTimeSinceLatestDatapoint,
      last_datapoint: event.date
    };
  }
  if (alarm.type === "HUMIDITY_ALERT") {
    const { value, unit } = getUnitValue(event.value);
    return {
      type: "LeakProtection" /* LeakProtection */,
      threshold: alarm.max,
      offset_direction: "gt" /* BaseGTCompare */,
      value: value ? value.toString() : "",
      unit: alarm.timeseries?.unit || (unit ? unit[0] : "")
    };
  }
  if (alarm.type === "VOC_MAX") {
    const { value, unit } = getUnitValue(event.value);
    return {
      type: "TimeseriesThresholdVOC" /* TimeseriesThresholdVOC */,
      threshold: alarm.max,
      offset_direction: "gt" /* BaseGTCompare */,
      value: value ? value.toString() : "",
      unit: alarm.timeseries?.unit || (unit ? unit[0] : "")
    };
  }
  if (alarm.type === "CO2_MAX") {
    const { value, unit } = getUnitValue(event.value);
    return {
      type: "TimeseriesThresholdCO2" /* TimeseriesThresholdCO2 */,
      threshold: alarm.max,
      offset_direction: "gt" /* BaseGTCompare */,
      value: value ? value.toString() : "",
      unit: alarm.timeseries?.unit || (unit ? unit[0] : "")
    };
  }
  if (alarm.type === "EFFECT_GUARD") {
    const { value, unit } = getUnitValue(event.value);
    return {
      type: "TimeseriesThresholdEffectGuard" /* TimeseriesThresholdEffectGuard */,
      threshold: alarm.max,
      offset_direction: "gt" /* BaseGTCompare */,
      value: value ? value.toString() : "",
      unit: alarm.timeseries?.unit || (unit ? unit[0] : "")
    };
  }
  if (alarm.type === "EPRED_MAX" || alarm.type === "EPRED_MIN") {
    const { value, unit } = getUnitValue(event.value);
    return {
      type: "CompareTimeseriesPredictedEnergyConsumption" /* CompareTimeseriesPredictedEnergyConsumption */,
      threshold: getUnitValue(event.comparisonValue)?.value,
      offset_direction: alarm.type === "EPRED_MAX" ? "gt" /* BaseGTCompare */ : "lt" /* BaseLTCompare */,
      value: value ? value.toString() : "",
      unit: alarm.timeseries?.unit || (unit ? unit[0] : "")
    };
  }
  throw Error(`Alarm type not found: ${alarm.type}`);
};
function getAlarmEvent({
  dataSetId,
  assetIds,
  alarm,
  event,
  endTime
}) {
  return {
    dataSetId,
    type: "alarm_event" /* ALARM */,
    subtype: getNewAlarmNameFromOld(alarm.type),
    assetIds,
    description: `${alarm.name}`,
    startTime: event.date.valueOf(),
    ...endTime ? { endTime } : {},
    metadata: {
      trigger_info: JSON.stringify(getTriggerInfoForAlarmEvent(alarm, event)),
      alarm_definition_id: event.alarmId.toString(),
      simplified_priority: getSimplifiedPriorityFromOldPriority(alarm.severity),
      priority: getNumericPriority(
        getSimplifiedPriorityFromOldPriority(alarm.severity)
      ).toString(),
      ...event.id ? { original_alarm_event_id: event.id } : {}
    },
    source: "User" /* User */
  };
}

// src/alarms/utils.ts
function mergeAlertDaysWithSameTime({
  schedules,
  mergeFromTypes,
  mergeToType
}) {
  const results = [];
  const consumedIndices = [];
  schedules.forEach((schedule, index) => {
    if (!schedule) {
      return;
    }
    if (!mergeFromTypes.includes(schedule.day)) {
      consumedIndices.push(index);
      results.push(schedule);
    }
  });
  schedules.forEach((schedule, index) => {
    if (consumedIndices.includes(index) || !schedule) {
      return;
    }
    const matchingIndices = [index];
    for (let j = index + 1; j < schedules.length; j++) {
      if (consumedIndices.includes(j)) {
        continue;
      }
      const otherSchedule = schedules[j];
      if (otherSchedule === void 0) {
        continue;
      }
      if (schedule.day === otherSchedule.day) {
        continue;
      }
      if (schedule.start_time !== otherSchedule.start_time || schedule.end_time !== otherSchedule.end_time || schedule.all_day !== otherSchedule.all_day) {
        continue;
      }
      matchingIndices.push(j);
    }
    if (matchingIndices.length === mergeFromTypes.length) {
      results.push({
        ...schedule,
        day: mergeToType
      });
      consumedIndices.push(...matchingIndices);
    } else {
      results.push(schedule);
      consumedIndices.push(index);
    }
  });
  if (consumedIndices.length !== schedules.length) {
    throw new Error("Parsed all given schedules, but not all were consumed!");
  }
  return results;
}
function mergeWeekendAlertDaysWithSameTime({
  schedules
}) {
  return mergeAlertDaysWithSameTime({
    schedules,
    mergeToType: "WEEKENDS" /* Weekends */,
    mergeFromTypes: ["SATURDAY" /* Saturday */, "SUNDAY" /* Sunday */]
  });
}
function mergeWeekdayAlertDaysWithSameTime({
  schedules
}) {
  return mergeAlertDaysWithSameTime({
    schedules,
    mergeToType: "WEEKDAYS" /* Weekdays */,
    mergeFromTypes: [
      "MONDAY" /* Monday */,
      "TUESDAY" /* Tuesday */,
      "WEDNESDAY" /* Wednesday */,
      "THURSDAY" /* Thursday */,
      "FRIDAY" /* Friday */
    ]
  });
}
function setAllDayWhenTimeAllDay({
  schedules
}) {
  return schedules.map((day) => {
    if (day.start_time === "00:00" && day.end_time === "23:59") {
      return {
        day: day.day,
        all_day: true,
        start_time: void 0,
        end_time: void 0
      };
    }
    if (day.all_day && (day.start_time || day.end_time)) {
      return {
        day: day.day,
        all_day: true,
        start_time: void 0,
        end_time: void 0
      };
    }
    return day;
  });
}
function removeDuplicateDays({
  schedules
}) {
  return schedules.filter(
    (schedule, i, schedules2) => schedules2.findIndex(
      (otherSchedule) => otherSchedule.day === schedule.day && !otherSchedule.all_day === !schedule.all_day && otherSchedule.start_time === schedule.start_time && otherSchedule.end_time === schedule.end_time
    ) === i
  );
}
function cleanupAlertSchedules({
  schedules
}) {
  let cleanedSchedules = setAllDayWhenTimeAllDay({ schedules });
  cleanedSchedules = removeDuplicateDays({ schedules: cleanedSchedules });
  cleanedSchedules = mergeWeekendAlertDaysWithSameTime({
    schedules: cleanedSchedules
  });
  return mergeWeekdayAlertDaysWithSameTime({
    schedules: cleanedSchedules
  });
}
function getContactFromPhoneOrEmail(recipient) {
  if (recipient?.phone) {
    return recipient.phone;
  }
  return recipient.email;
}
function getPhoneOrEmailFromContact(contact) {
  if (contact.indexOf("@") > 0) {
    return {
      email: contact
    };
  }
  return {
    phone: contact
  };
}

// src/utils.ts
var hd = new Holidays();
hd.init("NO", { types: ["public", "bank"] });
var getOperationalHoursAsDays = (operationalHours) => {
  if (!operationalHours) {
    return DAYS.map(() => ["00:00", "24:00"]);
  }
  let operationalHoursCopy = [...operationalHours];
  const days = Array(7);
  const weekdays = operationalHours.find((el) => el.period === "WEEKDAYS");
  if (weekdays) {
    days[0] = days[1] = days[2] = days[3] = days[4] = [weekdays.start, weekdays.end];
    operationalHoursCopy = operationalHoursCopy.filter((el) => el !== weekdays);
  }
  const weekends = operationalHours.find((el) => el.period === "WEEKENDS");
  if (weekends) {
    days[5] = days[6] = [weekends.start, weekends.end];
    operationalHoursCopy = operationalHoursCopy.filter((el) => el !== weekends);
  }
  operationalHoursCopy.forEach((day) => {
    days[DAYS.indexOf(day.period)] = [day.start, day.end];
  });
  return days;
};
var isOperationalDay = (day, operationalHours) => {
  const days = getOperationalHoursAsDays(operationalHours);
  return !!days[day];
};
var HOUR_FORMAT = "HH:mm";
var isOperationalHour = (date, operationalHours, closedPublicHolidays) => {
  const day = date.isoWeekday() - 1;
  const days = closedPublicHolidays && hd.isHoliday(date.toDate()) ? [] : getOperationalHoursAsDays(operationalHours);
  const dates = days[day];
  if (dates) {
    const start = dayjs(dates[0], HOUR_FORMAT);
    const end = dayjs(dates[1], HOUR_FORMAT);
    const current = dayjs(date.format(HOUR_FORMAT), HOUR_FORMAT);
    return current.isBetween(start, end, void 0, "[)");
  }
  return false;
};
var comparisonOperatorToComparator = {
  "<": (a, b, buffer = 0) => a < b + buffer,
  ">": (a, b, buffer = 0) => a > b - buffer,
  "<=": (a, b, buffer = 0) => a <= b + buffer,
  ">=": (a, b, buffer = 0) => a >= b - buffer
};
var getValueForMeasurement = (measurement) => {
  if (typeof measurement?.value !== "number") {
    return void 0;
  }
  return measurement?.unit === "%-akt" || measurement?.unit === "%-norm" ? measurement?.value * 100 : measurement?.value;
};
var restoreValueForMeasurement = (measurement) => {
  if (typeof measurement?.value !== "number") {
    return void 0;
  }
  return measurement?.unit === "%-akt" || measurement?.unit === "%-norm" ? measurement?.value / 100 : measurement?.value;
};
var FRACTION_DIGITS = {
  "\xB0C": 1,
  "%-akt": 1,
  "%-norm": 1,
  "%": 1,
  ppm: 3,
  m: 3,
  "kWh/m\xB2": 3,
  "m\xB3/s": 5
};
var getFractionDigits = (unit) => {
  return FRACTION_DIGITS[unit] ?? 1;
};
var formatMeasurement = (measurement, fractionDigits) => {
  if (measurement?.stateDescription && measurement?.value !== void 0) {
    const parsed = JSON.parse(
      measurement.stateDescription.replace(/'/g, '"')
    );
    const key = measurement.value.toString();
    const possibleValue = parsed[key];
    if (possibleValue) {
      return possibleValue;
    }
  }
  const numberFormat = new Intl.NumberFormat("nb-NO", {
    maximumFractionDigits: fractionDigits ?? getFractionDigits((measurement?.unit ?? "").trim())
  });
  const unit = formatUnit(measurement?.unit);
  const value = getValueForMeasurement(measurement);
  return value !== void 0 ? `${numberFormat.format(value)}${unit || ""}` : "";
};
var getSystemCodeFromExternalId = (id) => {
  const type = /=([n.\dK]+)/.exec(id);
  if (type && type.length > 1) {
    return type[1];
  }
  return "";
};
var getComponentFromExternalId = (id) => {
  const regExpMatchArray = [...id.matchAll(/-(\w\w\d\d\d)/gm)][0];
  if (regExpMatchArray && regExpMatchArray.length > 1) {
    return regExpMatchArray[regExpMatchArray.length - 1] ?? "";
  }
  return "";
};
var formatSubBuildingFromExternalId = (id) => {
  const subBuilding = /\+([\p{Letter}\d]+)/u.exec(id);
  return subBuilding && subBuilding.length > 1 ? subBuilding[1] : "";
};
var fileExtensionsForAutodeskViewer = [
  "3DM",
  "3DS",
  "A",
  "ASM",
  "AXM",
  "BRD",
  "CATPART",
  "CATPRODUCT",
  "CGR",
  "COLLABORATION",
  "DAE",
  "DDX",
  "DDZ",
  "DGK",
  "DGN",
  "DLV3",
  "DMT",
  "DWF",
  "DWFX",
  "DWG",
  "DWT",
  "DXF",
  "EMODEL",
  "EXP",
  "F3D",
  "FBRD",
  "FBX",
  "FSCH",
  "G",
  "GBXML",
  "GLB",
  "GLTF",
  "IAM",
  "IDW",
  "IFC",
  "IGE",
  "IGES",
  "IGS",
  "IPT",
  "IWM",
  "JT",
  "MAX",
  "MODEL",
  "MPF",
  "MSR",
  "NEU",
  "NWC",
  "NWD",
  "OBJ",
  "OSB",
  "PAR",
  "PMLPRJ",
  "PMLPRJZ",
  "PRT",
  "PSM",
  "PSMODEL",
  "RVT",
  "SAB",
  "SAT",
  "SCH",
  "SESSION",
  "SKP",
  "SLDASM",
  "SLDPRT",
  "SMB",
  "SMT",
  "STE",
  "STEP",
  "STL",
  "STLA",
  "STLB",
  "STP",
  "STPZ",
  "VPB",
  "VUE",
  "WIRE",
  "X_B",
  "X_T",
  "XAS",
  "XPR"
];
function roundToDigits(number, digits = 2) {
  return number.toFixed(digits).replace(/\.?0*$/, "");
}
var COMPONENTS = {
  A: {
    Komponentfunksjon: "B\xE6rende / Romdannende",
    Faganvendelse: "",
    Eksempler: ""
  },
  AB: {
    Komponentfunksjon: "Bjelke",
    Faganvendelse: "Bjelker, Drager, Dip, B\xE6ring",
    Eksempler: ""
  },
  AD: {
    Komponentfunksjon: "Dekker",
    Faganvendelse: "Plassbygde dekker",
    Eksempler: ""
  },
  AE: {
    Komponentfunksjon: "Elementer",
    Faganvendelse: "Monteringsferdige, Prefabrikerte elementer",
    Eksempler: ""
  },
  AF: {
    Komponentfunksjon: "Fagverk",
    Faganvendelse: "Bindingsverk, Stenderverk, Rigler, Rigel",
    Eksempler: ""
  },
  AG: {
    Komponentfunksjon: "Glassfelt",
    Faganvendelse: "Glass i glasstak",
    Eksempler: "Ikke vinduer"
  },
  AH: {
    Komponentfunksjon: "Fundamenter",
    Faganvendelse: "Peler, pelehode, grunndrager, pilar, punktfundament, s\xE5lefundament, gulv p\xE5 grunn",
    Eksempler: ""
  },
  AK: {
    Komponentfunksjon: "Komplette konstruksjoner",
    Faganvendelse: "Komplette enheter, Bygningsmessigekonstruksjoner, Store bygningsmessigeb\xE6rekonstruksjoner",
    Eksempler: ""
  },
  AL: {
    Komponentfunksjon: "List / Beslistning",
    Faganvendelse: "Listverk, Gulvlister, Taklister, D\xF8rlister, Folist, Gerikt, Holkil, Vaskelist, Vannbord, Vindski, Hj\xF8rnebord.",
    Eksempler: "Utvendig og innvendig belistning"
  },
  AO: {
    Komponentfunksjon: "Oppbygende / Utforende",
    Faganvendelse: "Foring: Betong, Lettklinkel, Leca, Tegl, Murstein, Treverk, Treplank, St\xE5l, Spr\xF8ytebetong",
    Eksempler: "Utforing, Oppforing, Nedforing, Utlekting, Opplekting, Nedlekting, Kledningsbord, Bordkledning, P\xE5st\xF8p, Forblending"
  },
  AP: {
    Komponentfunksjon: "Plate",
    Faganvendelse: "Bygningsmessige plater: Veggplater, Gulvplater, Gipsplater, Sponplater, Trefiberplater, Fin\xE9rplater",
    Eksempler: "Plater beregnet for videre behandling, Perforert plate, Kledningplater."
  },
  AR: {
    Komponentfunksjon: "Ramme / Oppheng",
    Faganvendelse: "Rammeverk. For bygningsmessige konstruksjoner: Montasjerammer, Stativ, Braketter, Opphengsskinner, Festeprofil, St\xE5lprofil, Baner, Barduner, Stag, Fundamenter, Sm\xE5 b\xE6rekonstruksjoner",
    Eksempler: "Rammer som ikke er systemer. For utstyr: CR_"
  },
  AS: {
    Komponentfunksjon: "S\xF8yle",
    Faganvendelse: "B\xE6rende stolper",
    Eksempler: "S\xF8yler som ikke er systemer"
  },
  AU: {
    Komponentfunksjon: "\xC5pninger",
    Faganvendelse: "Utsparing, hull",
    Eksempler: "\xC5pning etablert under bygging, hullboring og hulltak etter bygging"
  },
  AV: {
    Komponentfunksjon: "Vegg",
    Faganvendelse: "Komplett konstruksjon",
    Eksempler: "N\xE5r ikke eget system"
  },
  B: {
    Komponentfunksjon: "Innebyggende",
    Faganvendelse: "",
    Eksempler: ""
  },
  BA: {
    Komponentfunksjon: "Armering, Forsterkning",
    Faganvendelse: "Innst\xF8pt bjelke, Vinkeljern, St\xE5lvinkel, Kamst\xE5l, Armeringsnett",
    Eksempler: "St\xF8peskj\xF8ter, Forankringsklo, Forskaling"
  },
  BB: {
    Komponentfunksjon: "Beskyttende / Stoppende",
    Faganvendelse: "Membran, Folie, Duk, Blyskjerm",
    Eksempler: "Bl.a. i forbindelse med fuktighet"
  },
  BC: {
    Komponentfunksjon: "Begrensende",
    Faganvendelse: "Vindplate, Vindpapp, St\xF8ysperre",
    Eksempler: ""
  },
  BF: {
    Komponentfunksjon: "Fuging",
    Faganvendelse: "Fugemasse, Fugeinnlegg, Fugeprofil, Kitt, Silikon",
    Eksempler: "Fuger, Foging, Foger"
  },
  BG: {
    Komponentfunksjon: "Pakning, Tetning",
    Faganvendelse: "",
    Eksempler: ""
  },
  BI: {
    Komponentfunksjon: "Isolasjon",
    Faganvendelse: "Glassull, Mineralull",
    Eksempler: "Alle typer isolasjon unntatt brannisolasjon; se QZ"
  },
  BP: {
    Komponentfunksjon: "Avretningsmasse",
    Faganvendelse: "P\xE5st\xF8p, Puss, Sparkel, Helsparkling, Tynnavretting, M\xF8rtel",
    Eksempler: "Avretting av alle overflater"
  },
  BS: {
    Komponentfunksjon: "Spikerslag",
    Faganvendelse: "",
    Eksempler: ""
  },
  C: {
    Komponentfunksjon: "Kompletterende / Utspringende",
    Faganvendelse: "",
    Eksempler: ""
  },
  CB: {
    Komponentfunksjon: "Balkong",
    Faganvendelse: "",
    Eksempler: "S\xE5 fremt disse ikke er egne system"
  },
  CC: {
    Komponentfunksjon: "Baldakin",
    Faganvendelse: "",
    Eksempler: "S\xE5 fremt disse ikke er egne system"
  },
  CD: {
    Komponentfunksjon: "Karnapp",
    Faganvendelse: "",
    Eksempler: "S\xE5 fremt disse ikke er egne system"
  },
  CG: {
    Komponentfunksjon: "Rampe / Repos",
    Faganvendelse: "Gangbaner, Rullestolramper, Elefantrist, (repoer) Store rister (for mindre rister benyttes MR)",
    Eksempler: "S\xE5 fremt disse ikke er egne system"
  },
  CK: {
    Komponentfunksjon: "Komplett konstruksjon",
    Faganvendelse: "Komplett installasjon, Komplett enhet, Utsyr",
    Eksempler: "Systemnr. m\xE5 beskrive hvilken konstruksjon/enhet."
  },
  CM: {
    Komponentfunksjon: "Kjemiske stoffer",
    Faganvendelse: "Lim, Tilsetningsstoff, Pigmenter, Fargestoff, Herder, Tynner, Primer, Grunning, Lake, Lut, S\xE5pe, Sepe, Glykolblanding, Kj\xF8lemedium, Olje",
    Eksempler: 'Kan ogs\xE5 benyttes for "naturlige stoffer": Luft, Vann, Gass'
  },
  CO: {
    Komponentfunksjon: "Kobling, Overgang",
    Faganvendelse: "Muffer, Flenser, Bend, B\xF8y, Albu, Fittings \u2013 (Fysisk overgang, forbindelse eller retningsendring), Jordingsmuffe. Adapter.",
    Eksempler: ""
  },
  CP: {
    Komponentfunksjon: "Pipe / Skostein",
    Faganvendelse: "Eksosr\xF8r, R\xF8ykr\xF8r",
    Eksempler: ""
  },
  CQ: {
    Komponentfunksjon: "Festemateriell",
    Faganvendelse: "Skruer, Bolter, Nagler, Stift, Klammer, Hengsler, Sikringsbolter",
    Eksempler: ""
  },
  CR: {
    Komponentfunksjon: "Rammeverk / Oppheng",
    Faganvendelse: "Rammekonstruksjon for Utstyr og Installasjoner, Montasjerammer, Stativ, Braketter, Opphengsskinner, St\xE5lprofil, Baner, Barduner, Stag, Unistrut, Kabelbro, Kabelstige, Kabelbaner, St\xF8ttebein, Bunnramme, Sykkelstativ.",
    Eksempler: "S\xE5 fremt disse ikke er egne system. Rammer for bygningsmessige konstruksjoner: AR_"
  },
  CT: {
    Komponentfunksjon: "Trapp / Leider",
    Faganvendelse: "Videltrapp, Taktrapp, Stiger",
    Eksempler: "S\xE5 fremt disse ikke er egne system"
  },
  CX: {
    Komponentfunksjon: "Tunnel / Bru (inne/ute)",
    Faganvendelse: "Gangbruer, Tunneller, Broer",
    Eksempler: "S\xE5 fremt disse ikke er egne system"
  },
  D: {
    Komponentfunksjon: "\xC5pnende",
    Faganvendelse: "",
    Eksempler: ""
  },
  DB: {
    Komponentfunksjon: "D\xF8r med brannklasse",
    Faganvendelse: "Brannd\xF8rer",
    Eksempler: "Ytterd\xF8rer og innerd\xF8rer"
  },
  DF: {
    Komponentfunksjon: "Folded\xF8r / Foldevegg",
    Faganvendelse: "Skyvevegger",
    Eksempler: "Skyved\xF8rer: DI_"
  },
  DI: {
    Komponentfunksjon: "D\xF8r - innvendig",
    Faganvendelse: "Innvendige D\xF8rer; St\xE5ld\xF8rer, Tred\xF8rer, Glassd\xF8rer, Skyved\xF8rer",
    Eksempler: "Innerd\xF8rer"
  },
  DK: {
    Komponentfunksjon: "Kj\xF8ret\xF8yregulering",
    Faganvendelse: "Bom, pullert, sperretrinn",
    Eksempler: "For faste pullerter, se VM"
  },
  DL: {
    Komponentfunksjon: "Luke",
    Faganvendelse: "Renseluker, Inspeksjonsluker, Brannluker, R\xF8ykluker, Overlysluker, Tilfluktsromsluker, Seglass, Tomdel, Inspeksjonsdel, Kumlokk",
    Eksempler: "Alle former for luker"
  },
  DP: {
    Komponentfunksjon: "Port",
    Faganvendelse: "Rulleporter, Foldeporter, Skyveporter",
    Eksempler: "Porter inne og ute"
  },
  DT: {
    Komponentfunksjon: "D\xF8r - tilfluktsrom",
    Faganvendelse: "Tilfluktsromsd\xF8rer",
    Eksempler: ""
  },
  DU: {
    Komponentfunksjon: "D\xF8r - utvendig",
    Faganvendelse: "Utvendige D\xF8rer; Inngangsd\xF8rer, Utgangsd\xF8rer, D\xF8rer mot det fri.St\xE5ld\xF8rer, Tred\xF8rer, Glassd\xF8rer, Skyved\xF8rer",
    Eksempler: "Ytterd\xF8rer"
  },
  DV: {
    Komponentfunksjon: "Vindu",
    Faganvendelse: "Innervinduer, Yttervinduer, Takvinduer",
    Eksempler: "Inne og ute, Grupperes (ikke glassfelt)"
  },
  E: {
    Komponentfunksjon: "Bekledende",
    Faganvendelse: "",
    Eksempler: ""
  },
  EB: {
    Komponentfunksjon: "Overflatebekledning",
    Faganvendelse: "Veggbelegg, Gulvbelegg, Banebelegg, Vinylbelegg, Linoleum, Tepper, Matter, D\xF8rmatter, Fliser, Skifer, Stein, Tregulv, Parkett, Panel, Baderomspanel, R\xF8rmantling, Takpapp, Beslag (blikk, st\xE5l, aluminium)",
    Eksempler: "Ulike typer grupperes etter l\xF8penummer. Plater med ferdig overflate/overflatebehandling, Veggplater, Perforert plate, Gesimsbeslag, S\xE5lbenkbeslag, Sokkelbeslag"
  },
  EC: {
    Komponentfunksjon: "Overflatebehandling",
    Faganvendelse: "Maling, Lakk, Beis, Lut, Impregnering, Antigrafitti, Trebehandlingsolje, Vedlikeholdsolje, Strie, Tapet",
    Eksempler: "Ulike typer grupperes etter l\xF8penummer"
  },
  EH: {
    Komponentfunksjon: "Himling",
    Faganvendelse: "Himlingsplater, Himlingskassetter, Himlingsspiler, Alle typer himling ogs\xE5 systemhimlinger Takplater",
    Eksempler: ""
  },
  F: {
    Komponentfunksjon: "Innredende Veggfast",
    Faganvendelse: "",
    Eksempler: ""
  },
  FA: {
    Komponentfunksjon: "Ventilert arbeidsplass",
    Faganvendelse: "Avtrekkskap, Avtrekksbenker, LAF-Benk, Punktavsug, N\xE6ravsug, Kj\xF8kkenhetter, Avtrekkshetter",
    Eksempler: ""
  },
  FB: {
    Komponentfunksjon: "Benk / Bord / Plate / Tavle",
    Faganvendelse: "Arbeidsbenker, Arbeidsbord, Skrivebord, Oppslagstavler, Skrivetavle, Whiteboard",
    Eksempler: "Veggfast"
  },
  FC: {
    Komponentfunksjon: "Beslag",
    Faganvendelse: "Kj\xF8kkenbeslag, Laboratoriebeslag, Rustfritt Beslag",
    Eksempler: "Benyttes ved v\xE5te rom"
  },
  FD: {
    Komponentfunksjon: "Disk / Skranke",
    Faganvendelse: "Kateter",
    Eksempler: ""
  },
  FF: {
    Komponentfunksjon: "Fryserom",
    Faganvendelse: "",
    Eksempler: "Fryseskap: GF_"
  },
  FH: {
    Komponentfunksjon: "Hylle / Reol",
    Faganvendelse: "",
    Eksempler: "Veggfast"
  },
  FI: {
    Komponentfunksjon: "Kabinett",
    Faganvendelse: "Kabinett, Dusjhj\xF8rne, Dusjkabinett (prefabrikkert)",
    Eksempler: ""
  },
  FK: {
    Komponentfunksjon: "Kj\xF8lerom / Svalrom",
    Faganvendelse: "",
    Eksempler: "Kj\xF8leskap: GK_"
  },
  FO: {
    Komponentfunksjon: "Sittebenk / Sofa / Stol",
    Faganvendelse: "Sofa, Klappstol,Stoler, Krakk, Toalettsete, Klossettsete",
    Eksempler: "Veggfast"
  },
  FR: {
    Komponentfunksjon: "Rom",
    Faganvendelse: "Prefabrikerte Rom, Spesialrom; T\xF8rkerom, Audiorom",
    Eksempler: "Fryserom: FF_ - Kj\xF8lerom: FK_"
  },
  FS: {
    Komponentfunksjon: "Skap / Skuff",
    Faganvendelse: "Alle typer fastmonterte skap - El. skap, Kabinett, Rack (brannskap: se -UO)",
    Eksempler: "Veggfast. Tavle, elektrisk underfordeling: Selve skapet/fordelingen, som system, skal har eget systemnr."
  },
  FT: {
    Komponentfunksjon: "Speil",
    Faganvendelse: "",
    Eksempler: ""
  },
  FV: {
    Komponentfunksjon: "Vaskemaskin",
    Faganvendelse: "Sengevaskemaskiner, Bilvaskemaskiner",
    Eksempler: "Store, innbebygde"
  },
  FX: {
    Komponentfunksjon: "Krok / Knagg / H\xE5ndtak",
    Faganvendelse: "L\xF8ftekroker, D\xF8rvridere, Panikkbeslag (for d\xF8r\xE5pning)",
    Eksempler: ""
  },
  G: {
    Komponentfunksjon: "M\xF8blerende / Utstyr / Inventar",
    Faganvendelse: "",
    Eksempler: ""
  },
  GA: {
    Komponentfunksjon: "Automat / Maskin",
    Faganvendelse: "Kj\xF8kkenmaskiner, Kaffetrakter, Kaffemaskin, Juice, Kakkaomaskin",
    Eksempler: ""
  },
  GB: {
    Komponentfunksjon: "Benk / Bord / Plate / Tavle",
    Faganvendelse: "Arbeidsbenker, Arbeidsbord, Skrivebord, Oppslagstavler, Skrivetavle, Whiteboard",
    Eksempler: "L\xF8st utstyr"
  },
  GD: {
    Komponentfunksjon: "Dekontamator",
    Faganvendelse: "Bekkenspyler",
    Eksempler: "Desinfisering"
  },
  GE: {
    Komponentfunksjon: "Autoklaver",
    Faganvendelse: "",
    Eksempler: "Sterilisering"
  },
  GF: {
    Komponentfunksjon: "Fryseskap",
    Faganvendelse: "Fryseboks, Frysedisk",
    Eksempler: "Fryserom: FF_ - Kj\xF8lerom: FK_"
  },
  GG: {
    Komponentfunksjon: "Gardiner / Forheng",
    Faganvendelse: "Gobeliner, Senetepper, Filmlerret",
    Eksempler: ""
  },
  GH: {
    Komponentfunksjon: "Hylle / Reol",
    Faganvendelse: "",
    Eksempler: "L\xF8st utstyr"
  },
  GK: {
    Komponentfunksjon: "Kj\xF8leskap / Kj\xF8ledisk",
    Faganvendelse: "Kj\xF8leboks, Svaleskap",
    Eksempler: "Kj\xF8lerom: FK_"
  },
  GL: {
    Komponentfunksjon: "L\xE5s / Beslag",
    Faganvendelse: "L\xE5skasser, L\xE5ssylinder, L\xE5skontakt, Systemsylinder",
    Eksempler: "Grupperes etterl\xF8penummer"
  },
  GM: {
    Komponentfunksjon: "Mattilbereding",
    Faganvendelse: "Gryter, Panner, Komfyr, Mikrob\xF8lgeovn, Plater, Stekebord, Kokeskap, Dampskap",
    Eksempler: "Varmeskap for mat"
  },
  GN: {
    Komponentfunksjon: "N\xF8kler",
    Faganvendelse: "N\xF8kkelkort, Systemn\xF8kkel",
    Eksempler: "Typeunik"
  },
  GO: {
    Komponentfunksjon: "Sofa / Sittebenk",
    Faganvendelse: "",
    Eksempler: "Gruppering etter l\xF8penummer - typeunik merking"
  },
  GP: {
    Komponentfunksjon: "Stol",
    Faganvendelse: "",
    Eksempler: "Gruppering etter l\xF8penummer - typeunik merking"
  },
  GQ: {
    Komponentfunksjon: "Seng / Liggebenk",
    Faganvendelse: "L\xF8st utstyr",
    Eksempler: ""
  },
  GS: {
    Komponentfunksjon: "Skap / Skuffer",
    Faganvendelse: "",
    Eksempler: "L\xF8st utstyr"
  },
  GT: {
    Komponentfunksjon: "T\xF8rkeskap / Varmeskap",
    Faganvendelse: "",
    Eksempler: "Ikke for mat"
  },
  GV: {
    Komponentfunksjon: "Vaskemaskin",
    Faganvendelse: "Kl\xE6r, Mopper, Instrumentvaskemasin, Oppvaskemaskiner",
    Eksempler: ""
  },
  GW: {
    Komponentfunksjon: "Vekt",
    Faganvendelse: "Komplette vekter. Veieaparater, m\xE5leapparater, veieseller.",
    Eksempler: "For veieselle eller selve sensoren, se RV"
  },
  GX: {
    Komponentfunksjon: "Holder",
    Faganvendelse: "Toalettrullholdere, T\xF8rkepapirstativ, Dispenser, S\xE5peholder, Sepebeholder",
    Eksempler: ""
  },
  GY: {
    Komponentfunksjon: "Avfallsbeholder",
    Faganvendelse: "Avfallsb\xF8tte, s\xF8ppelb\xF8tte, papirkurv, bosspann",
    Eksempler: ""
  },
  H: {
    Komponentfunksjon: "Hjelpende (Mobilt)",
    Faganvendelse: "",
    Eksempler: ""
  },
  HA: {
    Komponentfunksjon: "Automobil / Bil",
    Faganvendelse: "",
    Eksempler: ""
  },
  HB: {
    Komponentfunksjon: "Rullebord / Tralle",
    Faganvendelse: "Lasteapparat",
    Eksempler: ""
  },
  HC: {
    Komponentfunksjon: "Container / Vogn",
    Faganvendelse: "Containere, kasse, patron for r\xF8rpost",
    Eksempler: ""
  },
  HM: {
    Komponentfunksjon: "Maskin",
    Faganvendelse: "Komprimatorer, Kverner",
    Eksempler: "All typer maskiner som ikke har egen betegnelse"
  },
  HS: {
    Komponentfunksjon: "Rullestol",
    Faganvendelse: "G\xE5stoler",
    Eksempler: ""
  },
  HT: {
    Komponentfunksjon: "Truck / kran",
    Faganvendelse: "L\xF8fteredskap Stasjon\xE6re og mobile kraner",
    Eksempler: ""
  },
  HV: {
    Komponentfunksjon: "Verkt\xF8y",
    Faganvendelse: "",
    Eksempler: ""
  },
  I: {
    Komponentfunksjon: "Produserende",
    Faganvendelse: "",
    Eksempler: ""
  },
  IB: {
    Komponentfunksjon: "Brenner",
    Faganvendelse: "Oljebrenner, Gassbrennere",
    Eksempler: ""
  },
  IC: {
    Komponentfunksjon: "Solceller/solfangere",
    Faganvendelse: "Solceller, solfangere, kombinasjonsaggregater",
    Eksempler: "Til produksjon av elektrisitet ved hjelp av fotoelektrisk effekt. For termisk varmeopptak fra sollys. Kogenerering av lys og varme. CHPaggregat (combined heat and power.)"
  },
  ID: {
    Komponentfunksjon: "Kjel for destruksjon",
    Faganvendelse: "Kjeler for s\xF8ppelforbrenning",
    Eksempler: ""
  },
  IE: {
    Komponentfunksjon: "Elektrokjel",
    Faganvendelse: "Elementkjel, Elektrodekjeler",
    Eksempler: "Vann og damp"
  },
  IF: {
    Komponentfunksjon: "Kjel for fast/bio brensel",
    Faganvendelse: "Biokjeler",
    Eksempler: ""
  },
  IG: {
    Komponentfunksjon: "Generator",
    Faganvendelse: "For Str\xF8mproduksjon, Reservekraft, N\xF8dstr\xF8m",
    Eksempler: ""
  },
  IK: {
    Komponentfunksjon: "Kuldeaggregat",
    Faganvendelse: "Kj\xF8lemaskin, Frysemaskin, Varmepumpe, Kj\xF8leuniter, Splituniter (p\xE5 system =358), Romkj\xF8lere",
    Eksempler: "Hele maskinen = IK -> selve kompressoren = JK"
  },
  IL: {
    Komponentfunksjon: "Energibr\xF8nn",
    Faganvendelse: "Infiltrasjonsbr\xF8nn, indirekte kollektorbr\xF8nn for varmepumpe",
    Eksempler: "Br\xF8nn for varme eller kj\xF8ling"
  },
  IM: {
    Komponentfunksjon: "Motor",
    Faganvendelse: "Forbrenningsmotorer: Dieselmotor, Bensinmotor, Sterlingmotor",
    Eksempler: "Aktuator: KA_ - Elektomotor: XM_"
  },
  IO: {
    Komponentfunksjon: "Oljekjel",
    Faganvendelse: "",
    Eksempler: ""
  },
  IP: {
    Komponentfunksjon: "Gasskjel",
    Faganvendelse: "Propan etc",
    Eksempler: ""
  },
  IS: {
    Komponentfunksjon: "Spesielt",
    Faganvendelse: "",
    Eksempler: ""
  },
  IT: {
    Komponentfunksjon: "Trykkluftaggregat (enhet)",
    Faganvendelse: "Trykkluftmaskin, Trykkluftkompressor",
    Eksempler: "Hele maskinen = IT -> selve kompressoren = JK"
  },
  IU: {
    Komponentfunksjon: "Turbin",
    Faganvendelse: "",
    Eksempler: ""
  },
  IV: {
    Komponentfunksjon: "Aggregatenhet",
    Faganvendelse: "Ventilasjon: f.eks. kompaktaggregat, St\xF8vsuger, Sentralst\xF8vsuger - Sammensatt utstyr",
    Eksempler: "Komplett aggregat = IV -> selve viften = JV"
  },
  J: {
    Komponentfunksjon: "Forsterkende",
    Faganvendelse: "",
    Eksempler: "Tur/tilluft = J_4__ Retur/fraluft = J_5__"
  },
  JF: {
    Komponentfunksjon: "Forsterker",
    Faganvendelse: "Lydforsterker, Lysforsterker, Signalforsterkere,",
    Eksempler: ""
  },
  JK: {
    Komponentfunksjon: "Kompressor",
    Faganvendelse: "Selve Kompressoren -> Komplett Maskin Se I_",
    Eksempler: ""
  },
  JP: {
    Komponentfunksjon: "Pumpe",
    Faganvendelse: "Pumper for alle medium. Sirkulasjonspumpe(- 40), Isvannspumpe(-41), Gjenvinningspumpe(- 50), Trykk\xF8kningspumpe, Brennstoffpumpe, Prosesspumpe, Matevannspumper, Kondenspumpe, Manuell pumpe",
    Eksempler: "Ulike typer grupperes etter l\xF8penummer gruppe. De ulike pumper brukes i ulike systemer slik at oppdelingen blir tilfredstillende. Tur = JP4__ Retur = JP5__"
  },
  JQ: {
    Komponentfunksjon: "Pumpe i VA-installasjoner",
    Faganvendelse: "Utomhus ,Avl\xF8pspumper, Grunnvannspumpe, Kommunalt Nettvann, Matepumpe",
    Eksempler: "VA=1m utenfor yttervegg - Ulike typer grupperes etter l\xF8penummer gruppe."
  },
  JV: {
    Komponentfunksjon: "Vifte",
    Faganvendelse: "Tilluftsvifte(-40), Innbl\xE5sningsvifte(-40), Fraluftsvifte(-50), Avtrekksvifte(-50), Omluftsvifte(- 60), Aksialvifter, Kj\xF8let\xE5rnsvifte, Fordampervifte, Kondensatorvifte",
    Eksempler: "Ulike typer grupperes etter l\xF8penummer gruppe. Det skal benyttes JV4_ for tilluftsvifter og JV5_ for fraluftsvifter."
  },
  JW: {
    Komponentfunksjon: "Spesialvifte",
    Faganvendelse: "R\xF8ykgassvifter, EX-vifter, Gassvifter, Prosessvifter, Transportvifte, Sugeenhet, Luftport uten varme, R\xF8rpostvifter",
    Eksempler: "For eksempel komponent i en st\xF8vsuger"
  },
  K: {
    Komponentfunksjon: "Overf\xF8rende / Transporterende",
    Faganvendelse: "",
    Eksempler: ""
  },
  KA: {
    Komponentfunksjon: "Aktuator",
    Faganvendelse: "Spjeldmotor, Ventilmotor, Servomotor, El.- /Pneum.-/Hydraulisk, L\xE5smotor, Motorl\xE5s, D\xF8rlukker, Magnetholder (for brannd\xF8rer)",
    Eksempler: ""
  },
  KD: {
    Komponentfunksjon: "Drivhjul / Drev",
    Faganvendelse: "Reimskive, Tannhjul, Drivknute",
    Eksempler: "Se ogs\xE5: KO_ og LG_"
  },
  KE: {
    Komponentfunksjon: "Induktiv energioverf\xF8ring",
    Faganvendelse: "Komponent for overf\xF8ring av elkraft til b\xE6rbare enheter og transportmiddel uten direkte kobling.",
    Eksempler: ""
  },
  KG: {
    Komponentfunksjon: "Gjennomf\xF8ring",
    Faganvendelse: "Hylse",
    Eksempler: ""
  },
  KH: {
    Komponentfunksjon: "Transportenhet ( hevende, forflyttende)",
    Faganvendelse: "Heisstol, Heiskurv, B\xE5nd for: Transportb\xE5nd, Rulletrapper, Rulleb\xE5nd,",
    Eksempler: "NB kan ogs\xE5 ha samme benevnelse som system"
  },
  KJ: {
    Komponentfunksjon: "Jordingskomponenter",
    Faganvendelse: "Jordelektrode, Jordleder, Jordskinne",
    Eksempler: "Jordelektrode nedgravd i bakken, fundamentjord, ringjord, spyd, jordplate."
  },
  KK: {
    Komponentfunksjon: "Kanal",
    Faganvendelse: "Ventilasjon- og el.kanaler, Metallkanal, Plastkanal, Kunststoffkanal, Kabelkanal, Installasjonskanal, Sykeromskanal",
    Eksempler: ""
  },
  KM: {
    Komponentfunksjon: "Mast / Antenne",
    Faganvendelse: "Stolpe, Stang",
    Eksempler: ""
  },
  KN: {
    Komponentfunksjon: "Nedl\xF8p",
    Faganvendelse: "Avl\xF8p, Sluk, Vannl\xE5s",
    Eksempler: ""
  },
  KO: {
    Komponentfunksjon: "Kraftoverf\xF8ring",
    Faganvendelse: "Drivrem, Kilerem, Kjede, Kjetting, Wire, Brems, Snor, Tau",
    Eksempler: "Se ogs\xE5: KD_ og LG_"
  },
  KQ: {
    Komponentfunksjon: "R\xF8r - spesielt",
    Faganvendelse: "Oljer\xF8r, Eksosr\xF8r, R\xF8kgass, R\xF8rpostr\xF8r, Trekker\xF8r, Elektrikerr\xF8r, Takrenner, Taknedl\xF8p",
    Eksempler: "Se ogs\xE5 utvendig eksosr\xF8r: CP_"
  },
  KR: {
    Komponentfunksjon: "R\xF8r - generelt",
    Faganvendelse: "R\xF8r, V\xE6ske, Damp, El (Installasjonsr\xF8r)",
    Eksempler: ""
  },
  KS: {
    Komponentfunksjon: "Skinne / Bane / Spor",
    Faganvendelse: "Str\xF8mskinner, Transportskinner, Gardinskinner, gardinstang, Jordingsskinne",
    Eksempler: "Telelift, Conveyer"
  },
  KT: {
    Komponentfunksjon: "Deler",
    Faganvendelse: "Deler til kanal- og r\xF8rsytemer. Bend, avgrening, albu, anboringsklammer. Lokk.",
    Eksempler: ""
  },
  KU: {
    Komponentfunksjon: "Kombinert kabel",
    Faganvendelse: "Samlekabel, flatkabel",
    Eksempler: "Kabler med flere spenninger. Komplette kabelbaner."
  },
  KV: {
    Komponentfunksjon: "H\xF8yspenningskabel > 1000V",
    Faganvendelse: "",
    Eksempler: "H\xF8yspenningskabler, h\xF8yspenningsleding"
  },
  KW: {
    Komponentfunksjon: "Lavspenningskabel 50 til 1000V",
    Faganvendelse: "50V til 1000V - Bl.a. Styrestr\xF8m og kraftkabler 230V og 400V",
    Eksempler: "Lavspentkabel, ledning, (sterkst\xF8mskabel)"
  },
  KX: {
    Komponentfunksjon: "Lavspenningskabel < 50V",
    Faganvendelse: "0V til 49V - SD-system, IT-kabler(metall), Signalkabel",
    Eksempler: "Svakstr\xF8mskabel, Ledning, Patchesnor, Koaksialkabel"
  },
  KY: {
    Komponentfunksjon: "Optisk kabel",
    Faganvendelse: "IT-kabel, Fiberkabler",
    Eksempler: ""
  },
  KZ: {
    Komponentfunksjon: "Slange",
    Faganvendelse: "Fleksibelt r\xF8r, Fleksible kanaler, Kompensator, Vibrasjonsdemper p\xE5 r\xF8r",
    Eksempler: "Fleksible koblinger for \xE5 oppta vibrasjoner. Slanger p\xE5 trommel: UO_"
  },
  L: {
    Komponentfunksjon: "Omformende / Vekslende",
    Faganvendelse: "",
    Eksempler: ""
  },
  LB: {
    Komponentfunksjon: "Varmeomformende med vifte",
    Faganvendelse: "Aerotempere, Luftvarmer, T\xF8rrkj\xF8lere, Kj\xF8let\xE5rn, Viftekonvektor, Varmluftsporter",
    Eksempler: "Uniter med egen vifte og kombinerte varme/kj\xF8leapparater med vifte."
  },
  LC: {
    Komponentfunksjon: "Kj\xF8leomformende med vifte",
    Faganvendelse: "Fan-coil",
    Eksempler: "Uniter med egen vifte. For kombinerte varme/kj\xF8leapparater benyttes LB.For aggregater med kompressor se \u2013IK"
  },
  LD: {
    Komponentfunksjon: "Kj\xF8leflater",
    Faganvendelse: "Kj\xF8letak, Kj\xF8lebafler, Kj\xF8lehimling",
    Eksempler: "For kombinerte varme/kj\xF8leflater benyttes LH. Kj\xF8lebatterier = LK"
  },
  LE: {
    Komponentfunksjon: "Kondensator",
    Faganvendelse: "DX, Condenser",
    Eksempler: "Kj\xF8lesystem"
  },
  LF: {
    Komponentfunksjon: "Fordamper",
    Faganvendelse: "DX, Evaporator",
    Eksempler: ""
  },
  LG: {
    Komponentfunksjon: "Gear, clutch",
    Faganvendelse: "Komplett enhet for kraftutveksling. Mekanisk: Hastighetregulator, Rotasjonsregulator",
    Eksempler: "Se ogs\xE5: KD_ og KO_"
  },
  LH: {
    Komponentfunksjon: "Varmeflate",
    Faganvendelse: "Radiatorer, Panelovner, Varmetak, Varmehimling, Eswa",
    Eksempler: "Benyttes ogs\xE5 for kombinerte varme/kj\xF8leflater. Varmebatterier = LV"
  },
  LI: {
    Komponentfunksjon: "Varmeelement",
    Faganvendelse: "Motorvarmer, Elektriske Varmeelementer",
    Eksempler: ""
  },
  LK: {
    Komponentfunksjon: "Kj\xF8leomformende",
    Faganvendelse: "Kj\xF8lebatteri, Veksler, Konvektor",
    Eksempler: "Gjennomstr\xF8mning (uten egen vifte)"
  },
  LL: {
    Komponentfunksjon: "Lyskilde",
    Faganvendelse: "Lysp\xE6re, Lysr\xF8r, Lysstoffr\xF8r, Gl\xF8delampe, Halogen, Damplampe",
    Eksempler: "Alle former for lyskilder"
  },
  LN: {
    Komponentfunksjon: "Likeretter",
    Faganvendelse: "Likerettere",
    Eksempler: ""
  },
  LO: {
    Komponentfunksjon: "Omformer",
    Faganvendelse: "Signalomvandler, Signalomformer, M\xE5leverdiomformere, Converter, Direct Box, DI box (for audio signal), HF modulator",
    Eksempler: ""
  },
  LP: {
    Komponentfunksjon: "Pens / Veksel / Sjalter",
    Faganvendelse: "Vekselventil",
    Eksempler: "R\xF8rpost, Telelift, Conveyer"
  },
  LQ: {
    Komponentfunksjon: "Vekselretter",
    Faganvendelse: "DC/AC-omformer",
    Eksempler: ""
  },
  LR: {
    Komponentfunksjon: "Frekvensomformer",
    Faganvendelse: "Elektronisk hastighetsregulator, Mykstarter, Rotasjonsregulator, Frekvensregulator",
    Eksempler: "For regulering av hastighet/turtall"
  },
  LS: {
    Komponentfunksjon: "Str\xE5levarme",
    Faganvendelse: "Str\xE5leovner, Str\xE5letak, Gassovn, Peis",
    Eksempler: ""
  },
  LU: {
    Komponentfunksjon: "Luftfukter",
    Faganvendelse: "Dampbefukter, Skivebefukter, Dysebefuktere",
    Eksempler: ""
  },
  LV: {
    Komponentfunksjon: "Varmeomformende",
    Faganvendelse: "Varmebatteri, Varmeveksler, Konvektor",
    Eksempler: "Gjennomstr\xF8mning (uten egen vifte)"
  },
  LX: {
    Komponentfunksjon: "Varmegjenvinner",
    Faganvendelse: "Kryssvarmeveksler, Roterende Varmeveksler, Glykolgjenvinnere, Batteriveksler",
    Eksempler: ""
  },
  LZ: {
    Komponentfunksjon: "Varmekabel / Varmer\xF8r",
    Faganvendelse: "R\xF8rslynge, Varmeslynge, Elektriske Varmekabler",
    Eksempler: ""
  },
  M: {
    Komponentfunksjon: "Filtrerende / Rensende",
    Faganvendelse: "",
    Eksempler: "Tur/tilluft = M_4__ Retur/fraluft = M_5__"
  },
  MA: {
    Komponentfunksjon: "Absoluttfilter",
    Faganvendelse: "Hepafilter, RO-filter (vannbehandling)",
    Eksempler: ""
  },
  MB: {
    Komponentfunksjon: "ABC-filter",
    Faganvendelse: "Krigsfilter, Tilfluktsromsfilter",
    Eksempler: ""
  },
  MC: {
    Komponentfunksjon: "UV-filter",
    Faganvendelse: "UV-lampe",
    Eksempler: ""
  },
  ME: {
    Komponentfunksjon: "Elektrostatiske filter",
    Faganvendelse: "Ioniserende, Elysator",
    Eksempler: ""
  },
  MF: {
    Komponentfunksjon: "Luftfilter",
    Faganvendelse: "Grovfilter, Finfilter, Posefilter",
    Eksempler: ""
  },
  MG: {
    Komponentfunksjon: "Fettfilter",
    Faganvendelse: "",
    Eksempler: ""
  },
  MK: {
    Komponentfunksjon: "Kondenspotte",
    Faganvendelse: "Kondensutskiller, Kondensavskiller, Dreneringpotter, Drenspotter",
    Eksempler: ""
  },
  ML: {
    Komponentfunksjon: "Luftutskiller",
    Faganvendelse: "Mikrobobleutskillere, Luftepotter",
    Eksempler: ""
  },
  MM: {
    Komponentfunksjon: "Membran",
    Faganvendelse: "Tynn duk for luftgjennomstr\xF8mning, Tynn filterduk",
    Eksempler: ""
  },
  MO: {
    Komponentfunksjon: "Utskiller",
    Faganvendelse: "Oljeutskiller, Fettutskillere",
    Eksempler: ""
  },
  MR: {
    Komponentfunksjon: "Rist / Sil",
    Faganvendelse: "Inntaksrist, Avkastrist, Ytterveggrist, Takhatt, Jethette, Slukrist, Fotskraperister",
    Eksempler: ""
  },
  MS: {
    Komponentfunksjon: "Syklon",
    Faganvendelse: "St\xF8vutskiller",
    Eksempler: ""
  },
  MT: {
    Komponentfunksjon: "T\xF8rke",
    Faganvendelse: "T\xF8rkefilter, Avfukter, Vannavskiller, Vannutskiller, Oljet\xF8rker, Trykkluftt\xF8rker",
    Eksempler: ""
  },
  MU: {
    Komponentfunksjon: "Filter for Lyd / Bilde / Frekvensutjevner",
    Faganvendelse: "Grafisk equaliser (Equalizer/Limiter), Elektronisk Gate (Quad Noise Gate), Kompressor i lydsystem (Stereo Compressor/Limiter/Gate)",
    Eksempler: ""
  },
  MV: {
    Komponentfunksjon: "Vannfilter",
    Faganvendelse: "Filtrering av vann",
    Eksempler: "for RO-filter se MA"
  },
  MX: {
    Komponentfunksjon: "St\xF8yfilter",
    Faganvendelse: "Elektrisk st\xF8y",
    Eksempler: ""
  },
  N: {
    Komponentfunksjon: "Lagrende",
    Faganvendelse: "",
    Eksempler: ""
  },
  NB: {
    Komponentfunksjon: "Batteri / UPS",
    Faganvendelse: "Str\xF8mbatterier, Str\xF8makkumulator, No-Break, Avbruddsfri str\xF8mforsyning, Batteripakke",
    Eksempler: ""
  },
  NC: {
    Komponentfunksjon: "Kondensator",
    Faganvendelse: "Elektrisk",
    Eksempler: "Alternativt: XC_"
  },
  NI: {
    Komponentfunksjon: "Informasjonslagring",
    Faganvendelse: "Diskett, Hardisk, CD, Tape, Magnetb\xE5nd, Film, Kassett, Papirer, Dokumenter, Lister",
    Eksempler: "Brukerh\xE5ndb\xF8ker, H\xE5ndbok, Perm, Veiledning, Instruks (Digitale, Elektroniske eller Papirformat)"
  },
  NK: {
    Komponentfunksjon: "Kum",
    Faganvendelse: "Septiktank, Pumpekum, Trekkekummer",
    Eksempler: ""
  },
  NM: {
    Komponentfunksjon: "Badekar / Basseng",
    Faganvendelse: "",
    Eksempler: ""
  },
  NO: {
    Komponentfunksjon: "\xC5pen tank",
    Faganvendelse: "Kar",
    Eksempler: ""
  },
  NT: {
    Komponentfunksjon: "Tank med trykk",
    Faganvendelse: "Gasstank, Trykklufttank, Ekspansjonstanker, Trykkavgasser, Deareater",
    Eksempler: ""
  },
  NU: {
    Komponentfunksjon: "Tank uten trykk",
    Faganvendelse: "Vanntanker, Isvannstanker, Oljetanker, Drivstofftanker, Kondenstank, Matevannstank, Oppsamlingstanker, Akkumulatortanker, Prosesstanker, Sisterner, Avfallsbeholder, Afallsb\xF8tte, S\xF8ppelb\xF8tte, S\xF8pleb\xF8tte, Bosspann, Papirkurv",
    Eksempler: "Oppbevaring og lager: Alle tanker og beholdere uten trykk (med ubetydelig trykk). Container: -HC"
  },
  NV: {
    Komponentfunksjon: "Vekt, Lodd",
    Faganvendelse: "Motvekt, last, tyngde",
    Eksempler: ""
  },
  NW: {
    Komponentfunksjon: "Varmtvannsbereder",
    Faganvendelse: "",
    Eksempler: "Tank med varmeelement( el,vann, damp)"
  },
  NX: {
    Komponentfunksjon: "Toalett",
    Faganvendelse: "Toaletter, Klosett, Bid\xE9, WC, Urinal, Pissoar",
    Eksempler: ""
  },
  NY: {
    Komponentfunksjon: "Servant",
    Faganvendelse: "Servanter, Vasker, Vaskekummer, Utslagsvask",
    Eksempler: ""
  },
  NZ: {
    Komponentfunksjon: "Brannslukkingsapparat",
    Faganvendelse: "Brannslukningsapparater, H\xE5ndslukkeapparater",
    Eksempler: ""
  },
  O: {
    Komponentfunksjon: "Prosesserende",
    Faganvendelse: "",
    Eksempler: ""
  },
  OA: {
    Komponentfunksjon: "AV-maskiner",
    Faganvendelse: "Lyd og Bilde: Videomaskiner, Videokanoner, Transview, Filmfremvisere, B\xE5ndopptagere, Kassettspillere, CD-spillere, Projektor, Projector, DVD-spiller",
    Eksempler: ""
  },
  OB: {
    Komponentfunksjon: "Shuntgruppe",
    Faganvendelse: "Shuntgrupper komplett",
    Eksempler: "(for selve shuntventilen, se SB..)"
  },
  OD: {
    Komponentfunksjon: "Datamaskin",
    Faganvendelse: "PC, Dataterminal",
    Eksempler: ""
  },
  OE: {
    Komponentfunksjon: "Energim\xE5ler",
    Faganvendelse: "Sentralenhet for utregning av energi",
    Eksempler: "Elektrisk-, varme- og kj\xF8leenergi"
  },
  OF: {
    Komponentfunksjon: "Systemb\xE6rer",
    Faganvendelse: "Virtuelle objekter for system-komponenter der det ikke er ett naturlig objekt som starter et system. Systemb\xE6rer ventilasjonssystem, r\xF8rsystem v\xE6ske, r\xF8rsystem for gass eller trykkluft, avl\xF8pssystem, brannslukkingssystem",
    Eksempler: "Ogs\xE5 for \xE5 tilpasse til modelleringsverkt\xF8y som ikke tillater enkelte objekter \xE5 v\xE6re systemb\xE6rere."
  },
  OM: {
    Komponentfunksjon: "Mottaker / Sender",
    Faganvendelse: "Modem, Signalmottakere, Radiosendere, Radiomottaker, Alarmsender, Posisjonssender, Persons\xF8ker, IR-sender, Teleslynge",
    Eksempler: ""
  },
  OP: {
    Komponentfunksjon: "PBX",
    Faganvendelse: "",
    Eksempler: ""
  },
  OQ: {
    Komponentfunksjon: "Dataprogram,programvare",
    Faganvendelse: "Software",
    Eksempler: ""
  },
  OR: {
    Komponentfunksjon: "Router, Fordeler",
    Faganvendelse: "HUB, Switch, CSS, Bro, Fordelerskap, R\xF8rfordeling, Luftfordeler, Slot, Splitter",
    Eksempler: "Kommunikasjonsmidtpunkt, Koblingsskap i alle typer system."
  },
  OS: {
    Komponentfunksjon: "Sentralenhet, Mikser i Lydsystem",
    Faganvendelse: "Kontrollenhet (se ogs\xE5 OU), Hovedsentral, Dataserver, Telefonsentral, Alarmsentral, Brannsentral, Master-Node i LON, EIB eller BUSS-system, Miksebord (Mixing Console)",
    Eksempler: "Niv\xE5 over OU"
  },
  OT: {
    Komponentfunksjon: "Telefonapparat",
    Faganvendelse: "Mobiltelefon, Walkie Talkie (Transceiver)",
    Eksempler: ""
  },
  OU: {
    Komponentfunksjon: "Undersentral",
    Faganvendelse: "Kontrollenhet (se ogs\xE5 OS), Subsentraler, PLS, DDC, CPU, Node, Scenariomodul, Logikkmodul, Bustilkoblingsmodul, Enhet for prosessering i lydsystemer: Lydmatrise (Digital Matrise), Romklangenhet, Forsinkerenhet (Digital Delay)",
    Eksempler: "En modul inneholdende en CPU, Niv\xE5 under OS. Endestasjon for r\xF8rpost."
  },
  Q: {
    Komponentfunksjon: "Vernende / Dempende",
    Faganvendelse: "",
    Eksempler: ""
  },
  QB: {
    Komponentfunksjon: "Belastningsvakt",
    Faganvendelse: "Effektvakt",
    Eksempler: ""
  },
  QD: {
    Komponentfunksjon: "Differansetrykkvakt",
    Faganvendelse: "Filtervakt",
    Eksempler: "(med elektrisk signal)"
  },
  QE: {
    Komponentfunksjon: "Elektrisk vern",
    Faganvendelse: "Vern for elektrisk utstyr, Overspenningsvern, Overspenningsavledere, Spenningsvakt, 0- spenningsvern, EMP- vern (elektromagnetisk puls), Jordfeilbryter, Jordplate, Jordelektrode.",
    Eksempler: "Se ogs\xE5 XF_ for sikkringer etc Jordfeilvarsler = QS"
  },
  QF: {
    Komponentfunksjon: "Str\xF8mningsvakt",
    Faganvendelse: "Flow-switch, Vindvakt",
    Eksempler: ""
  },
  QH: {
    Komponentfunksjon: "Fuktvakt",
    Faganvendelse: "Hygrostat, Vannvakt, Regnvakt (QH90), Sn\xF8vakt (QH99)",
    Eksempler: ""
  },
  QL: {
    Komponentfunksjon: "Lyddemper",
    Faganvendelse: "Lydfelle, Lydplater, Lydisolasjon",
    Eksempler: ""
  },
  QM: {
    Komponentfunksjon: "Mekanisk beskyttelse",
    Faganvendelse: "Vern, Beslag, Beskyttelseslist, Fender, Puller, H\xE5ndtak, H\xE5ndl\xF8per, Rekkverk, Gelender, Skjerm, L\xE5sbeslag, D\xF8rpumpe, Dekkplate, Gjerde, Gitter, Stengsel, Dr\xE5pefanger, Bom, Sperre, Sikringsnett",
    Eksempler: "Bygningsmessig beskyttelse innvendig i bygget. For utvendig se VM."
  },
  QN: {
    Komponentfunksjon: "Niv\xE5vakt",
    Faganvendelse: "",
    Eksempler: "(med elektrisk signal)"
  },
  QO: {
    Komponentfunksjon: "Overtrykksventil",
    Faganvendelse: "Sjokkventiler (Tilfluktsrom), Luft, V\xE6ske",
    Eksempler: ""
  },
  QP: {
    Komponentfunksjon: "Trykkvakt",
    Faganvendelse: "Pressostat",
    Eksempler: "(med elektrisk signal)"
  },
  QQ: {
    Komponentfunksjon: "Vibrasjonsvakt",
    Faganvendelse: "",
    Eksempler: ""
  },
  QR: {
    Komponentfunksjon: "Rotasjonsvakt",
    Faganvendelse: "",
    Eksempler: ""
  },
  QS: {
    Komponentfunksjon: "Str\xF8mvakt",
    Faganvendelse: "Overv\xE5king av elektrisk str\xF8m, Jordfeilvarsler",
    Eksempler: ""
  },
  QT: {
    Komponentfunksjon: "Temperaturvakt",
    Faganvendelse: "Termostat (av/p\xE5), Frostvakt, Termokontakt.",
    Eksempler: "(med elektrisk signal)"
  },
  QV: {
    Komponentfunksjon: "Sikkerhetsventil",
    Faganvendelse: "",
    Eksempler: ""
  },
  QX: {
    Komponentfunksjon: "Solavskjerming",
    Faganvendelse: "Persienner, Markiser, Raster",
    Eksempler: ""
  },
  QY: {
    Komponentfunksjon: "Lynavleder",
    Faganvendelse: "",
    Eksempler: ""
  },
  QZ: {
    Komponentfunksjon: "Brannvern",
    Faganvendelse: "Mekanisk eller bygningsmessig brannbeskyttelse. Brannisolasjon, Branntetting, Branntetning, R\xF8yktetting, R\xF8yktetning.",
    Eksempler: "Komplett brannvegg; se AV"
  },
  R: {
    Komponentfunksjon: "Registrerende",
    Faganvendelse: "Sensor, giver, f\xF8ler. Se ogs\xE5 Q",
    Eksempler: "Tur/tilluft = R_4__ Retur/fraluft = R_5__"
  },
  RA: {
    Komponentfunksjon: "AV-opptaker",
    Faganvendelse: "Kamera, Mikrofon, Fotografiapparat",
    Eksempler: ""
  },
  RB: {
    Komponentfunksjon: "Bevegelse",
    Faganvendelse: "Bevegelsesdetektor, Tilstedev\xE6relsesdetektor, IR-Detektor, Infrar\xF8d, Presensf\xF8ler, Glassbruddsdetektor (lyd og rystelse)",
    Eksempler: ""
  },
  RC: {
    Komponentfunksjon: "Seismometer",
    Faganvendelse: "Seismisk detektor (Rystelse, lyd, varme)",
    Eksempler: ""
  },
  RD: {
    Komponentfunksjon: "Differansetrykkgiver",
    Faganvendelse: "Differansetrykkf\xF8ler, Differansetrykktransmitter, Differensm\xE5ling med elektr.signal.",
    Eksempler: "Kan ogs\xE5 brukes ved diff.m\xE5ling av andre variabler. For manuelt instrument, se RQ"
  },
  RE: {
    Komponentfunksjon: "Elektriske variabler",
    Faganvendelse: "Effektm\xE5ler, Str\xF8mm\xE5ler, Spenningsm\xE5ler, Intensitet, M\xE5letrafo",
    Eksempler: ""
  },
  RF: {
    Komponentfunksjon: "Str\xF8mningsm\xE5ler",
    Faganvendelse: "Flow Rate, Mengdem\xE5ler, Luftmengdem\xE5ler, Vannmengdem\xE5lere, M\xE5lekors, M\xE5leblende, Str\xF8mningsgiver, Volummeter",
    Eksempler: "Sensor for mengdem\xE5ling - volum og hastighet Massestr\xF8m, Volumstr\xF8m"
  },
  RG: {
    Komponentfunksjon: "Posisjon / Lengde",
    Faganvendelse: "Magnetkontakt, datamus, pekeredskap",
    Eksempler: ""
  },
  RH: {
    Komponentfunksjon: "Fuktighetsgiver",
    Faganvendelse: "Humidity, Fuktf\xF8ler, Luftfuktighetsf\xF8ler, Fuktighetsdetektor, Hygrometer (RHxx1), Vannf\xF8ler, Regnf\xF8ler (RH90), Sn\xF8f\xF8ler (RH99)",
    Eksempler: ""
  },
  RI: {
    Komponentfunksjon: "Termometer",
    Faganvendelse: "Avlesning av temperatur, Visende instrumenter \u2013 manuell avlesning.",
    Eksempler: "Det skal benyttes RI4_ for i tilluft/tur og RI5_ for i fraluft/retur o.s.v. se veiledning"
  },
  RJ: {
    Komponentfunksjon: "Fotocelle",
    Faganvendelse: "Fotoselle, Lysf\xF8ler, Solf\xF8ler",
    Eksempler: ""
  },
  RK: {
    Komponentfunksjon: "Kortleser",
    Faganvendelse: "Kortlesere, registrerende, avlesende, tastatur",
    Eksempler: ""
  },
  RM: {
    Komponentfunksjon: "Multifunksjonell, Kombinert f\xF8ler",
    Faganvendelse: "V\xE6rstasjon, Multivippe med bevegelsesmelder (i bus-system)",
    Eksempler: "Sammensatt enhet, multiregistrerende."
  },
  RN: {
    Komponentfunksjon: "Niv\xE5giver",
    Faganvendelse: "Niv\xE5m\xE5ler, H\xF8ydem\xE5ler",
    Eksempler: ""
  },
  RP: {
    Komponentfunksjon: "Trykkgiver",
    Faganvendelse: "Trykkf\xF8ler, Trykktransmitter, Vakumf\xF8ler",
    Eksempler: ""
  },
  RQ: {
    Komponentfunksjon: "Manometer, Trykkm\xE5ler",
    Faganvendelse: "Avlesning av trykk og differansetrykkm\xE5ler, U-r\xF8r, Visende instrumenter \u2013 manuell avlesning.",
    Eksempler: ""
  },
  RR: {
    Komponentfunksjon: "Giver generelt",
    Faganvendelse: "F\xF8lere/ sensorer/ givere av annen type enn \xF8vrige",
    Eksempler: "Eksempel: Konduktivitetssensor (vannets ledningsevne), pH-m\xE5ler"
  },
  RS: {
    Komponentfunksjon: "Hastighetsm\xE5ler",
    Faganvendelse: "Str\xF8mningsf\xF8ler, Speedometer, Frekvensm\xE5lere, Rotasjon, Vindm\xE5ler, Vindhastighetsm\xE5ler, Vindf\xF8ler",
    Eksempler: "Hastighetsm\xE5ler for mengdeberegning: RF_"
  },
  RT: {
    Komponentfunksjon: "Temperaturgiver",
    Faganvendelse: "Temperaturf\xF8ler, Temperaturtransmitter. For frostvakt se QT",
    Eksempler: "Det skal benyttes RT4_ for i tilluft/tur og RT5_ for i fraluft/retur"
  },
  RU: {
    Komponentfunksjon: "Ur",
    Faganvendelse: "Ur, Tidsur, \xC5rsur, Ukeur, Klokke",
    Eksempler: "Urbryter og Timer: XO_"
  },
  RV: {
    Komponentfunksjon: "Veieceller",
    Faganvendelse: "Veieceller, Fastmontert eller l\xF8s vektsensor. Veieaparater, M\xE5leapparater, vekter.",
    Eksempler: "For komplett vekt, se GW"
  },
  RW: {
    Komponentfunksjon: "Virkningsgradsm\xE5ler",
    Faganvendelse: "Komplett enhet",
    Eksempler: ""
  },
  RX: {
    Komponentfunksjon: "M\xE5lepunkt",
    Faganvendelse: "",
    Eksempler: ""
  },
  RY: {
    Komponentfunksjon: "Gassdetektor/R\xF8ykdetektor",
    Faganvendelse: "CO2-detektor, CO2-f\xF8ler, Luftkvalitetsm\xE5ler, Brannmelder, Linjedetektor",
    Eksempler: "Registrering av Branngasser"
  },
  RZ: {
    Komponentfunksjon: "Branndeteksjon",
    Faganvendelse: "F\xF8lere knyttet direkte til brannalarmsystem. Optisk r\xF8ykdetektor, Linjedetektor, Ionisk r\xF8ykdetektor, Varmedetektor, Flammedetektor, Aspirasjonsdetektor, Multisensor eller multikriterie",
    Eksempler: "Manuell brannmelder se XS_. Brannsentral, se OS_, OU_"
  },
  S: {
    Komponentfunksjon: "Stengende / Regulerende",
    Faganvendelse: "",
    Eksempler: "Tur/tilluft = S_4__ Retur/fraluft = S_5__"
  },
  SA: {
    Komponentfunksjon: "Reguleringsventil, manuell",
    Faganvendelse: "Manuelle ventiler",
    Eksempler: "2-Veis, 3-Veis, Fireveisventiler, Shuntventiler, Radiatorventil"
  },
  SB: {
    Komponentfunksjon: "Reguleringsventil, motorstyrt",
    Faganvendelse: "Motorstyrte ventiler, Direktevirkende, Pneumatiske",
    Eksempler: "2-Veis, 3-Veis, Fireveisventiler, Shuntventiler, Radiatorventil"
  },
  SC: {
    Komponentfunksjon: "Stengeventil, motorstyrt",
    Faganvendelse: "Motorstyrte, Av/P\xE5, Magnetventil, Spjeldventiler",
    Eksempler: ""
  },
  SD: {
    Komponentfunksjon: "Alarmventil sprinkler",
    Faganvendelse: "",
    Eksempler: ""
  },
  SE: {
    Komponentfunksjon: "Ekspansjonsventil",
    Faganvendelse: "",
    Eksempler: ""
  },
  SF: {
    Komponentfunksjon: "Fraluftsventil",
    Faganvendelse: "Ventilasjon: Avtrekksrist, Fraluftsrist, Avtrekksventiler",
    Eksempler: "Ytterveggsris: MR_ , Punktavsug: FA_"
  },
  SG: {
    Komponentfunksjon: "Tilbakeslagsventil / Overtrykkspjeld",
    Faganvendelse: "Hindre tilbakeslag",
    Eksempler: ""
  },
  SH: {
    Komponentfunksjon: "Hurtigkobling",
    Faganvendelse: "Uttakspost med aut.avstengning",
    Eksempler: "Se ogs\xE5: U_ - Uttak"
  },
  SI: {
    Komponentfunksjon: "Effektregulator",
    Faganvendelse: "Triac, Programkobler, Dimmer, Lysdimmer",
    Eksempler: "Regulering av avgitt effekt"
  },
  SJ: {
    Komponentfunksjon: "Jevntrykksventil",
    Faganvendelse: "Reduksjonsventiler, Stabilisator, T\xF8mmesentraler, Sprinklerventiler, Alarmventiler (sprinkler)",
    Eksempler: "Automatisk trykkregulering"
  },
  SK: {
    Komponentfunksjon: "Str\xF8mningsregulator - CAV",
    Faganvendelse: "Reguleringsenhet for konstant volum, - luftmengde",
    Eksempler: "Automatisk luftmengderegulering"
  },
  SM: {
    Komponentfunksjon: "Stengeventil, manuell",
    Faganvendelse: "Avstengingsventiler - Vann/V\xE6ske",
    Eksempler: "Se ogs\xE5: U_ - Uttak"
  },
  SP: {
    Komponentfunksjon: "Trykkutjevningsventil",
    Faganvendelse: "Overstr\xF8mningsventiler, Differansetrykksregulator",
    Eksempler: ""
  },
  SQ: {
    Komponentfunksjon: "Str\xF8mningsregulator - VAV",
    Faganvendelse: "Reguleringsenhet for variabelt volum, - luftmengde",
    Eksempler: "Automatisk luftmengderegulering"
  },
  SR: {
    Komponentfunksjon: "Reguleringsspjeld",
    Faganvendelse: "Innreguleringspjeld, Strupespjeld",
    Eksempler: ""
  },
  SS: {
    Komponentfunksjon: "Stengespjeld",
    Faganvendelse: "Ventilasjon: Inntak, Avkast, Omluft, By-Pass, Ledeskinnespjeld, Frostsikringsspjeld.",
    Eksempler: "Av/p\xE5 og modulerende"
  },
  ST: {
    Komponentfunksjon: "Tilluftsventil",
    Faganvendelse: "Ventilasjon: Tilluftsrist, Innbl\xE5sningsventiler, Tilluftskjerm",
    Eksempler: "Ytterveggsrist: MR_"
  },
  SU: {
    Komponentfunksjon: "Sugetrykksventil",
    Faganvendelse: "Undertrykksventil, Vakumventil",
    Eksempler: ""
  },
  SV: {
    Komponentfunksjon: "Strupeventil",
    Faganvendelse: "Innreguleringsventiler Vann/V\xE6ske",
    Eksempler: ""
  },
  SW: {
    Komponentfunksjon: "Plenumskammer",
    Faganvendelse: "Med Spjeld: Tilluftskammer, Fraluftskammer, Tilluftsboks",
    Eksempler: "Kan inng\xE5 i komplett ventil: SF_ eller ST_"
  },
  SX: {
    Komponentfunksjon: "Regulator",
    Faganvendelse: "",
    Eksempler: "Regulator for alle reguleringsenheter"
  },
  SZ: {
    Komponentfunksjon: "Brannspjeld / R\xF8ykspjeld",
    Faganvendelse: "",
    Eksempler: ""
  },
  U: {
    Komponentfunksjon: "Uttakende",
    Faganvendelse: "",
    Eksempler: ""
  },
  UA: {
    Komponentfunksjon: "Uttak alarm",
    Faganvendelse: "",
    Eksempler: ""
  },
  UB: {
    Komponentfunksjon: "Blandebatteri",
    Faganvendelse: "Servantbatterier, Termostatbatterier, Dusjbatterier, Sanit\xE6rarmaturer",
    Eksempler: ""
  },
  UD: {
    Komponentfunksjon: "Uttak data",
    Faganvendelse: "Datakontakt",
    Eksempler: ""
  },
  UE: {
    Komponentfunksjon: "Uttak el",
    Faganvendelse: "Stikkontakt, fastmontert ladeuttak for mobile enheter",
    Eksempler: ""
  },
  UF: {
    Komponentfunksjon: "Fellesuttak",
    Faganvendelse: "Uttak felles kablingssystem (f.eks. tele og data), Uttak fiberkabel, Uttak optisk fiber",
    Eksempler: ""
  },
  UG: {
    Komponentfunksjon: "Uttak gass",
    Faganvendelse: "",
    Eksempler: "Alle type gasser - ulike typer skilles p\xE5 l\xF8penr. system"
  },
  UH: {
    Komponentfunksjon: "H\xF8yttaler",
    Faganvendelse: "H\xF8retelefoner, Headset (ogs\xE5 med mikrofon), Subbass (Subwoofer), Loudspeaker",
    Eksempler: "Avgir lyd"
  },
  UJ: {
    Komponentfunksjon: "Skriver",
    Faganvendelse: "Listeutskrift, Printer, Skriveutstyr",
    Eksempler: ""
  },
  UK: {
    Komponentfunksjon: "Kontrollpanel / Tabl\xE5",
    Faganvendelse: "Betjeningspanel, Instrumentpanel, Styringspanel, Betjeningstabl\xE5, Brannmannspanel, Betjeningsterminal, Driftsterminal, Man\xF8verbord",
    Eksempler: "Komplett panel med brytere, lamper, display el.lign. Ikke datamaskin"
  },
  UL: {
    Komponentfunksjon: "Uttak trykkluft",
    Faganvendelse: "Trykkluftuttak",
    Eksempler: "Alle type trykkluft - ulike trykk skilles p\xE5 l\xF8penr. system"
  },
  UM: {
    Komponentfunksjon: "Monitor / Display",
    Faganvendelse: "TV-skjerm, Dataskjermer, Display, Digitalt viserinstument",
    Eksempler: ""
  },
  UN: {
    Komponentfunksjon: "N\xF8dbelysning",
    Faganvendelse: "",
    Eksempler: ""
  },
  UO: {
    Komponentfunksjon: "Trommel",
    Faganvendelse: "Tromler for slanger, ledninger, brannslangetrommel",
    Eksempler: "Inkl. oppheng, slanger, ledninger - Brannskap"
  },
  UP: {
    Komponentfunksjon: "Belysningsarmatur",
    Faganvendelse: "Lampe, Spotlight, Lyskaster, Lysarmatur",
    Eksempler: "Lyskilde/p\xE6re kan f\xE5 egen betegnelse: LL_"
  },
  UR: {
    Komponentfunksjon: "Uttak radio",
    Faganvendelse: 'Uttak "lyd", Kontakt for radio/musikk, Uttakspanel',
    Eksempler: ""
  },
  US: {
    Komponentfunksjon: "Stasjon",
    Faganvendelse: "Start, Endestasjon, Avslutningskap",
    Eksempler: "R\xF8rpost, Telelift, Conveyer"
  },
  UT: {
    Komponentfunksjon: "Uttak telefon",
    Faganvendelse: "Telefonkontakt",
    Eksempler: ""
  },
  UV: {
    Komponentfunksjon: "Uttak vann",
    Faganvendelse: "Vannuttak, Tappested, Vannkran, Vannutkaster",
    Eksempler: ""
  },
  UX: {
    Komponentfunksjon: "Koblingsboks",
    Faganvendelse: "(Sm\xE5 skap eller bokser som inneholder tilkoblingsklemmer.) Krysskoblingsskap, patchepanel",
    Eksempler: "Se ogs\xE5: FS_ og XX_"
  },
  UY: {
    Komponentfunksjon: "Uttak antenne",
    Faganvendelse: "Antennekontakt",
    Eksempler: ""
  },
  UZ: {
    Komponentfunksjon: "Dyse / Spreder",
    Faganvendelse: "Sprinklerhode, Injektor, Dusjhode, H\xE5nddusj, Dusjgarnityr, N\xF8ddusj.",
    Eksempler: ""
  },
  V: {
    Komponentfunksjon: "Utvendig",
    Faganvendelse: "Veier, plasser, sletter",
    Eksempler: ""
  },
  VB: {
    Komponentfunksjon: "B\xE6relag",
    Faganvendelse: "Stein, Pukk, Fyllmasser",
    Eksempler: ""
  },
  VD: {
    Komponentfunksjon: "Dekke",
    Faganvendelse: "Grus, Asfalt, Betong, Singel, Bark, Jord",
    Eksempler: ""
  },
  VG: {
    Komponentfunksjon: "Gress",
    Faganvendelse: "Plen, torv, slette",
    Eksempler: ""
  },
  VK: {
    Komponentfunksjon: "Kantstein, heller",
    Faganvendelse: "Steiner, forst\xF8ttning, trappestein",
    Eksempler: ""
  },
  VL: {
    Komponentfunksjon: "Masse",
    Faganvendelse: "L\xF8smasser, Fyllmasser, Drenslag, Dreneringsmasser",
    Eksempler: ""
  },
  VM: {
    Komponentfunksjon: "Mekanisk beskyttelse",
    Faganvendelse: "Vern, Beslag, Beskyttelseslist, Fender, Puller, H\xE5ndtak, H\xE5ndl\xF8per, Rekkverk, Gelender, Skjerm, L\xE5sbeslag, Dekkplate, Gjerde, Gitter, Stengsel, Dr\xE5pefanger, Bom, Sperre, Sikringsnett",
    Eksempler: "Kun utvendig - innvendig = QM"
  },
  VP: {
    Komponentfunksjon: "Planter,busker,tr\xE6r",
    Faganvendelse: "",
    Eksempler: ""
  },
  VS: {
    Komponentfunksjon: "Skilt",
    Faganvendelse: "Henvisning, Tavle, Veiviser, Klebetekst, Bokstaver, Tall, Merking, Merketape",
    Eksempler: "Utvendig og innvendig"
  },
  X: {
    Komponentfunksjon: "El. Produkter",
    Faganvendelse: "Basert p\xE5 IEC-norm 750 (1983) / NEC321 (med X_ foran). Hovedsakelig for tavlekomponenter.",
    Eksempler: "Alternativer er oppgitt for komponenter som skal ha annen betegnelse n\xE5r de ikke er montert i tavle."
  },
  XC: {
    Komponentfunksjon: "Kondensator",
    Faganvendelse: "Elektrisk",
    Eksempler: "Alternativt: NC_"
  },
  XD: {
    Komponentfunksjon: "Komp. for bin\xE6rlogikk",
    Faganvendelse: "",
    Eksempler: ""
  },
  XF: {
    Komponentfunksjon: "Komponenter for vern",
    Faganvendelse: "Sikrigner, Motorvern, Sikringsautomater, Automatsikring, Bimetall",
    Eksempler: "Se ogs\xE5: QE_"
  },
  XG: {
    Komponentfunksjon: "Komponenter for krafttilf\xF8rsel",
    Faganvendelse: "Spenningsmodul, Str\xF8mtilf\xF8rsel, Str\xF8mforsyningsenhet, Nettdel, Powesupply. I tavle ogs\xE5: Batteri",
    Eksempler: "Alternativt: NB_ for st\xF8rre selvstendige enheter"
  },
  XH: {
    Komponentfunksjon: "Komponenter for signalering",
    Faganvendelse: "Signallampe, Varsellampe, Lysdiode, Ringeklokke, Alarmklokke, Sirene, Stillingsviser, Summer, Horn",
    Eksempler: ""
  },
  XI: {
    Komponentfunksjon: "Potensiometer",
    Faganvendelse: "Potmeter",
    Eksempler: ""
  },
  XK: {
    Komponentfunksjon: "Releer / Kontaktorer",
    Faganvendelse: "Rel\xE9er, Hjelperele, M\xE5lerele",
    Eksempler: ""
  },
  XL: {
    Komponentfunksjon: "Induktiv komponenter",
    Faganvendelse: "Drosselspole,",
    Eksempler: ""
  },
  XM: {
    Komponentfunksjon: "Motor",
    Faganvendelse: "Elektromotorer",
    Eksempler: "Aktuator: KA_ - Forbrenningsmotor: IM_"
  },
  XN: {
    Komponentfunksjon: "Integrerte kretser",
    Faganvendelse: "for analogteknikk",
    Eksempler: ""
  },
  XO: {
    Komponentfunksjon: "Urbryter / Timer",
    Faganvendelse: "",
    Eksempler: "Ikke NEC. Se:RU_ for UR, Klokke"
  },
  XP: {
    Komponentfunksjon: "Komponenter for m\xE5ling og pr\xF8ving",
    Faganvendelse: "Visende og registrerende m\xE5leinstrumenter, Instument med visere, Viserinstrument",
    Eksempler: "Digitalt instument, Display: UM_"
  },
  XQ: {
    Komponentfunksjon: "Effektbryter",
    Faganvendelse: "Lastbryter for kraftteknikk, Sikringsskillebrytere",
    Eksempler: "Koblingsapparat i energikrets"
  },
  XR: {
    Komponentfunksjon: "Motstand",
    Faganvendelse: "",
    Eksempler: ""
  },
  XS: {
    Komponentfunksjon: "Bryter / Vender / Knapp / Vippe",
    Faganvendelse: "Switch, Utl\xF8serbrytere, Endebryter, Trykknapper, Manuelle brannmeldere",
    Eksempler: "Koblingsapparat for kontroll- og teleteknikk"
  },
  XT: {
    Komponentfunksjon: "Transformator",
    Faganvendelse: "Trafo, Spenningstransformatorer for opp- og nedtransformering av spenning, Lader",
    Eksempler: "M\xE5letrafo: RE_"
  },
  XV: {
    Komponentfunksjon: "Halvlederkomponenter og elektronr\xF8r",
    Faganvendelse: "Transistorer, Dioder (Lysdioder=XH_), Tyristorer",
    Eksempler: ""
  },
  XX: {
    Komponentfunksjon: "Rekkeklemmer / Samlesignal",
    Faganvendelse: "Koblingspunkt, Fellesfeil, Felles driftsignal, Potensialfrie signaler, Synkroniseringspuls",
    Eksempler: "BUS-system: utgang"
  },
  XZ: {
    Komponentfunksjon: "Terminering og tilpasning",
    Faganvendelse: "Inngangsmodul, Utgangsmodul, Inngangskort, Utgangskort, I/O-board, Gateway, Linjemodul, Sl\xF8yfekort, Adresseenhet, Interface, Grensesnitt",
    Eksempler: "BUS-system: brett med flere utganger Normalt teletekniske komponenter"
  },
  Y: {
    Komponentfunksjon: "Spesielt",
    Faganvendelse: "Spesielle komponenter og utstyr for bedriftens bygningsmasse",
    Eksempler: ""
  },
  Z: {
    Komponentfunksjon: "Spesielle prosessutstyr for virksomheten.",
    Faganvendelse: "Spesielle komponenter og utstyr for bedriftensprosesser.",
    Eksempler: ""
  }
};
var translateComponentType = (type) => {
  return COMPONENTS[type]?.Komponentfunksjon ?? `Ukjent (${type})`;
};
var formatTimeseriesName = (timeseries) => {
  const name = (/=[n.\dK]+-([^#]+)/.exec(timeseries.externalId ?? "") ?? [
    "",
    ""
  ])[1].replace("_", "-");
  const fromId = /\+(\w+)/.exec(timeseries.externalId ?? "");
  return `${getSystemCodeFromExternalId(timeseries.externalId ?? "")} ${name !== timeseries.name && name || ""} ${timeseries.name} ${timeseries.description || ""}${fromId && fromId.length > 1 ? ", " + fromId[1] : ""}`;
};
var getParsedRefreshRate = (refreshRate) => {
  if (refreshRate) {
    const [, interval, hour] = /(\d*\w+);(\d+:\d+)/.exec(refreshRate).values();
    return [interval, hour];
  }
  return [void 0, void 0];
};
var getSchedule = (ts) => {
  const rate = getParsedRefreshRate(ts.metadata?.refresh_rate);
  if (rate[1]) {
    return { interval: rate[0] || "5m", hour: rate[1] };
  }
  return { interval: rate[0] || "5m" };
};
function getTimeseriesLabel(ts) {
  return `${getSystemCodeFromExternalId(ts?.externalId ?? "")} ${ts?.description ?? ""} ${ts?.name ?? ""}`;
}
function getDayJsDayFromAlertDay(alertDay) {
  switch (alertDay) {
    case "MONDAY" /* Monday */:
      return 1;
    case "TUESDAY" /* Tuesday */:
      return 2;
    case "WEDNESDAY" /* Wednesday */:
      return 3;
    case "THURSDAY" /* Thursday */:
      return 4;
    case "FRIDAY" /* Friday */:
      return 5;
    case "SATURDAY" /* Saturday */:
      return 6;
    case "SUNDAY" /* Sunday */:
      return 0;
    default:
      throw new Error(`Invalid AlertDay: ${alertDay}`);
  }
}
var getUserNameWithEmail = (user) => {
  if (!user) {
    return "";
  }
  return user.name ? `${user.name} (${user.email})` : user.email;
};

// src/date.ts
function convertHoursToMS(hours) {
  return 1e3 * 60 * 60 * hours;
}
function convertMSToHours(milliseconds) {
  return Math.round(milliseconds / 1e3 / 60 / 60);
}
function isDate(value) {
  return Object.prototype.toString.call(value) === "[object Date]";
}

// src/replaceLodash.ts
var chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0)
    return cache;
  while (tmp.length)
    cache.push(tmp.splice(0, chunkSize));
  return cache;
};
function objectMergeDefaultArrayStrategy(_array1, array2) {
  return array2;
}
function objectMergeSetArrayStrategy(array1, array2) {
  const tmp = /* @__PURE__ */ new Set([...array1, ...array2]);
  return [...tmp];
}
function objectMerge(obj1, obj2, arrayMergeFunction) {
  if (!(obj1 instanceof Object) || !(obj2 instanceof Object)) {
    throw new Error("cannot merge objects when given non-object value");
  }
  if (Array.isArray(obj1) || Array.isArray(obj2)) {
    return (arrayMergeFunction ?? objectMergeDefaultArrayStrategy)(
      obj1,
      obj2
    );
  }
  const o1 = obj1;
  const o2 = obj2;
  const result = { ...o1 };
  for (const key in o2) {
    if (o2.hasOwnProperty(key)) {
      if (o2[key] instanceof Object && o1[key] instanceof Object) {
        result[key] = objectMerge(o1[key], o2[key]);
      } else {
        result[key] = o2[key];
      }
    }
  }
  return result;
}

// src/alerts/schemas.ts
import { z as z2 } from "zod";
var UserInfo = z2.object({
  name: z2.string().optional(),
  email: z2.string().email()
});
var ActionInfo = z2.object({
  by: UserInfo.extend({
    email: z2.string().email().optional()
  }),
  at: z2.number().int()
});
var AlertGroupMember = z2.object({
  name: z2.string().optional(),
  email: z2.string().email().optional(),
  phone: z2.string().optional(),
  //z.string().e164().optional(),
  properate_user_email: z2.string().email(),
  notify_by_email: z2.boolean(),
  notify_by_phone: z2.boolean()
});
var Archived = ActionInfo.nullish();
var AlertGroup = z2.object({
  id: z2.string().optional(),
  name: z2.string(),
  access_control_groups: z2.string().array().optional(),
  members: AlertGroupMember.array(),
  building_external_id: z2.string()
});
var AlertGroupClientSide = AlertGroup.extend({
  id: z2.string(),
  created: ActionInfo,
  updated: ActionInfo.optional(),
  archived: Archived,
  highlight: z2.object({
    name: z2.string().optional()
  }).optional()
});
var AlertGroupSearchResult = z2.object({
  items: AlertGroupClientSide.array(),
  next_cursor: z2.string().optional()
});
var OrderByBase = z2.enum(["name:ASC", "name:DESC"]);
var OrderByIncident = z2.enum([
  ...OrderByBase.options,
  "start:ASC",
  "start:DESC"
]);
var OrderBy = OrderByIncident.or(OrderByBase);
var BaseFilter = z2.object({
  building_external_id: z2.string().optional()
});
var IncidentFilter = BaseFilter.extend({
  all: z2.boolean().optional(),
  only_active: z2.boolean().optional(),
  mine: z2.boolean().optional(),
  resolved: z2.boolean().optional(),
  unresolved: z2.boolean().optional(),
  in_progress: z2.boolean().optional()
});
var Filter = IncidentFilter.or(BaseFilter);
var ListSearchParams = z2.object({
  search: z2.string().optional(),
  order_by: OrderBy.optional(),
  filter: Filter.optional(),
  cursor: z2.string().optional(),
  limit: z2.number().positive().int().optional()
});
var GroupRule = z2.object({
  group_id: z2.string(),
  notify_by_priority: z2.object({
    timeout: z2.number().int().gte(0).optional(),
    notify_all: z2.boolean().optional()
  })
});
var GroupRuleWithPeriod = GroupRule.extend({
  period: z2.object({
    day: z2.nativeEnum(AlertDay),
    hourStart: z2.number().int().gte(0).lt(24),
    hourEnd: z2.number().int().gte(0).lt(24),
    minuteStart: z2.number().int().gte(0).lt(60),
    minuteEnd: z2.number().int().gte(0).lt(60)
  }).or(
    z2.object({
      day: z2.nativeEnum(AlertDay),
      hourStart: z2.null(),
      hourEnd: z2.null(),
      minuteStart: z2.null(),
      minuteEnd: z2.null()
    })
  )
});
var AlertRule = z2.object({
  id: z2.string().optional(),
  building_external_id: z2.string(),
  access_control_groups: z2.string().array().optional(),
  name: z2.string(),
  alarm_rule_selector: z2.object({
    category: z2.string().array().optional(),
    severity: z2.string().array().optional()
  }).optional(),
  alarm_rule_ids: z2.string().array().optional(),
  group_rules: GroupRuleWithPeriod.array().optional(),
  group_rules_exceptions: z2.nativeEnum(AlertDay).array().optional(),
  fallback_group_rule: GroupRule.optional(),
  muted: z2.boolean().optional(),
  escalation_policy: z2.object({
    delay: z2.number().int().gte(0),
    notify_all: z2.boolean()
  }).optional(),
  type: z2.enum(["select", "query"])
});
var AlertRuleClientSide = AlertRule.extend({
  id: z2.string(),
  created: ActionInfo,
  updated: ActionInfo.optional(),
  archived: Archived,
  highlight: z2.object({
    name: z2.string().optional()
  }).optional()
});
var AlertRuleSearchResult = z2.object({
  items: AlertRuleClientSide.array(),
  next_cursor: z2.string().optional()
});
var Incident = z2.object({
  id: z2.string().optional(),
  name: z2.string(),
  building_external_id: z2.string(),
  building_id: z2.number(),
  building_address: z2.string(),
  access_control_groups: z2.string().array().optional(),
  alarm_event_id_list: z2.number().array(),
  start: z2.number().int(),
  end: z2.number().int().optional(),
  responsible: z2.object({
    user: UserInfo,
    timestamp: z2.number().int()
  }).optional(),
  notified_people: z2.object({
    user: UserInfo.array(),
    timestamp: z2.number().int()
  }).optional(),
  assigned: z2.object({
    user: UserInfo,
    timestamp: z2.number().int()
  }).optional(),
  status: z2.enum(["unresolved", "in_progress", "resolved"]),
  has_active_event: z2.boolean(),
  last_comment: z2.string().optional(),
  timeseries_name: z2.string().optional(),
  priority: z2.number().int().gte(0).lt(255),
  alarm_rule_id: z2.string(),
  alarm_rule_name: z2.string(),
  alert_rule_id: z2.string().optional(),
  alerted_group_id: z2.string().optional(),
  owner: z2.string(),
  alert_next_at: z2.number().int().nullish(),
  task_id: z2.string().optional()
});
var IncidentClientSide = Incident.extend({
  created: ActionInfo,
  updated: ActionInfo.optional(),
  archived: Archived,
  highlight: z2.object({
    name: z2.string().optional()
  }).optional()
});
var IncidentSearchResult = z2.object({
  items: IncidentClientSide.array(),
  next_cursor: z2.string().optional()
});
var GetUnassignedAlarmsResponse = z2.object({
  unassignedAlarmsLength: z2.number().int().nullable(),
  assignedAlarmsLength: z2.number().int().nullable(),
  totalAlarmsLength: z2.number().int().nullable()
});
var GetUnassignedAlarmsRequestParams = z2.object({
  building_external_id: z2.string()
});

// src/alerts/getCurrentResponsibleGroup.ts
import dayjs2 from "@properate/dayjs";
var comparePeriodAndDay = (period, day) => {
  if (period === "WEEKENDS" && (day === 0 || day === 6)) {
    return true;
  }
  if (period === "WEEKDAYS" && day >= 1 && day <= 5) {
    return true;
  }
  return day === getDayJsDayFromAlertDay(period);
};
var isNowInExceptions = (now, exceptions) => {
  if (!exceptions?.length) {
    return false;
  }
  return exceptions.some(
    (exception) => comparePeriodAndDay(exception, now.day())
  );
};
var isTimeBetweenPeriod = (startHour, startMinutes, endHour, endMinutes, time) => {
  const now = dayjs2().tz("Europe/Oslo");
  const startTime = now.hour(startHour).minute(startMinutes).second(0);
  const endTime = now.hour(endHour).minute(endMinutes).second(0);
  return time.isAfter(startTime) && time.isBefore(endTime);
};
var getCurrentResponsibleGroup = (alertConfiguration) => {
  const now = dayjs2().tz("Europe/Oslo");
  const isInException = isNowInExceptions(
    now,
    alertConfiguration.group_rules_exceptions
  );
  if (!isInException) {
    const regularGroupRule = alertConfiguration.group_rules?.find(
      (groupRule) => {
        if (comparePeriodAndDay(groupRule.period.day, now.day()) && (!groupRule.period.hourStart || isTimeBetweenPeriod(
          groupRule.period.hourStart,
          groupRule.period.minuteStart,
          groupRule.period.hourEnd,
          groupRule.period.minuteEnd,
          now
        ))) {
          return groupRule;
        }
        return false;
      }
    );
    if (regularGroupRule) {
      return regularGroupRule;
    }
  }
  if (alertConfiguration.fallback_group_rule) {
    return alertConfiguration.fallback_group_rule;
  }
  return null;
};

// src/indexer/types.ts
var TimeseriesSearchIndexField = /* @__PURE__ */ ((TimeseriesSearchIndexField2) => {
  TimeseriesSearchIndexField2["id"] = "id";
  TimeseriesSearchIndexField2["externalId"] = "externalId";
  TimeseriesSearchIndexField2["dataSetId"] = "dataSetId";
  TimeseriesSearchIndexField2["name"] = "name";
  TimeseriesSearchIndexField2["extendedDescription"] = "extendedDescription";
  TimeseriesSearchIndexField2["buildingId"] = "buildingId";
  TimeseriesSearchIndexField2["assetParentId"] = "assetParentId";
  TimeseriesSearchIndexField2["labels"] = "labels";
  TimeseriesSearchIndexField2["translatedLabels"] = "translatedLabels";
  TimeseriesSearchIndexField2["system"] = "system";
  TimeseriesSearchIndexField2["category"] = "category";
  TimeseriesSearchIndexField2["subBuilding"] = "subBuilding";
  TimeseriesSearchIndexField2["unit"] = "unit";
  TimeseriesSearchIndexField2["types"] = "types";
  TimeseriesSearchIndexField2["clientId"] = "clientId";
  TimeseriesSearchIndexField2["stateDescription"] = "stateDescription";
  return TimeseriesSearchIndexField2;
})(TimeseriesSearchIndexField || {});
var TimeseriesNewIndexDisplayedAttributes = [
  "id" /* id */,
  "externalId" /* externalId */,
  "name" /* name */,
  "system" /* system */,
  "subBuilding" /* subBuilding */,
  "translatedLabels" /* translatedLabels */,
  "unit" /* unit */,
  "dataSetId" /* dataSetId */,
  "stateDescription" /* stateDescription */
];
var TimeseriesNewIndexSearchableAttributes = [
  "name" /* name */,
  "system" /* system */,
  "subBuilding" /* subBuilding */,
  "translatedLabels" /* translatedLabels */,
  "unit" /* unit */
];
var TimeseriesNewIndexFilterableAttributes = [
  "system" /* system */,
  "category" /* category */,
  "subBuilding" /* subBuilding */,
  "buildingId" /* buildingId */,
  "labels" /* labels */,
  "translatedLabels" /* translatedLabels */,
  "unit" /* unit */,
  "types" /* types */
];
var TimeseriesNewIndexSortableAttributes = [
  "name" /* name */,
  "system" /* system */,
  "subBuilding" /* subBuilding */,
  "translatedLabels" /* translatedLabels */,
  "unit" /* unit */
];
var SearchIndexName = /* @__PURE__ */ ((SearchIndexName2) => {
  SearchIndexName2["timeseries"] = "timeseries";
  SearchIndexName2["timeseriesNew"] = "timeseriesNew";
  SearchIndexName2["timeseriesExternalIds"] = "timeseriesExternalIds";
  SearchIndexName2["timeseriesExternalIdsNew"] = "timeseriesExternalIdsNew";
  SearchIndexName2["rooms"] = "rooms";
  SearchIndexName2["roomsNew"] = "roomsNew";
  SearchIndexName2["components"] = "components";
  SearchIndexName2["componentsNew"] = "componentsNew";
  SearchIndexName2["assets"] = "assets";
  SearchIndexName2["assetsNew"] = "assetsNew";
  return SearchIndexName2;
})(SearchIndexName || {});
export {
  ASSET_DESCRIPTIONS,
  ActiveAlarmPeriod,
  Alarm,
  AlarmEventState,
  AlarmEventWithId,
  AlarmRule,
  AlarmRuleSource,
  AlarmRuleTypeName,
  AlarmSchedule,
  AlarmSeverity,
  AlarmSubscription,
  AlarmType,
  AlarmWithId,
  AlarmWithRequiredId,
  AlarmWithSnapshotId,
  AlertDay,
  AlertGroup,
  AlertGroupClientSide,
  AlertGroupMember,
  AlertGroupSearchResult,
  AlertRecipient,
  AlertRule,
  AlertRuleClientSide,
  AlertRuleSearchResult,
  BUILDING_CATEGORIES,
  BUILDING_TYPES,
  COMPONENTS,
  CogniteEventType,
  CommonAlarmRuleConditionFields,
  CommonAlarmRuleOffsetFields,
  CommonAlarmRuleTypeSpecificFields,
  CommonAlarmRuleTypeSpecificSchedule,
  CompareTimeseriesAlarmRule,
  ComparisonTimeseries,
  DAYS,
  DAYS_SUNDAY_FIRST,
  DERIVED_UNITS,
  DayType,
  EffectGuardAlarmRule,
  EffectGuardTenantType,
  FilterType,
  GetUnassignedAlarmsRequestParams,
  GetUnassignedAlarmsResponse,
  GroupRule,
  GroupRuleWithPeriod,
  InactiveAlarmPeriod,
  Incident,
  IncidentClientSide,
  IncidentFilter,
  IncidentSearchResult,
  LeakProtectionAlarmRule,
  ListSearchParams,
  MissingDataAlarmRule,
  OP,
  OffsetDirection,
  OrderBy,
  OrderByBase,
  OrderByIncident,
  PERIOD_VALUES,
  PartialAlarmRule,
  Period,
  PhoneOrEmail,
  ProperateEvent,
  SEVERITY_TITLE,
  SearchIndexName,
  SimplifiedAlarmPriority,
  TFM_SYSTEM_NUMBERS,
  ThirdPartyAlarmRule,
  ThirdPartyAlarmVisualTimeseries,
  ThirdPartyAlarmVisualTimeseriesType,
  TimeSpanObject,
  TimeseriesNewIndexDisplayedAttributes,
  TimeseriesNewIndexFilterableAttributes,
  TimeseriesNewIndexSearchableAttributes,
  TimeseriesNewIndexSortableAttributes,
  TimeseriesSearchIndexField,
  TimeseriesThresholdAlarmRule,
  TimeseriesType,
  UserInfo,
  chunk,
  cleanupAlertSchedules,
  comparisonOperatorToComparator,
  convertElementPropsToUnit,
  convertHoursToMS,
  convertMSToHours,
  fileExtensionsForAutodeskViewer,
  formatMeasurement,
  formatSubBuildingFromExternalId,
  formatTimeseriesName,
  formatUnit,
  getAlarmEvent,
  getComponentFromExternalId,
  getContactFromPhoneOrEmail,
  getCurrentResponsibleGroup,
  getDayJsDayFromAlertDay,
  getFractionDigits,
  getNewAlarmNameFromOld,
  getNumericPriority,
  getOperationalHoursAsDays,
  getParsedRefreshRate,
  getPhoneOrEmailFromContact,
  getSchedule,
  getSimplifiedPriority,
  getSimplifiedPriorityFromOldPriority,
  getSystemCodeFromExternalId,
  getTimeseriesLabel,
  getUnitWithDerivedUnits,
  getUserNameWithEmail,
  getValueForMeasurement,
  isDate,
  isOperationalDay,
  isOperationalHour,
  isSpecialPctUnit,
  keysForAlarmPriority,
  keysForAlertDays,
  mergeAlertDaysWithSameTime,
  mergeWeekdayAlertDaysWithSameTime,
  mergeWeekendAlertDaysWithSameTime,
  minutesToMs,
  msToMinutes,
  objectMerge,
  objectMergeDefaultArrayStrategy,
  objectMergeSetArrayStrategy,
  removeDuplicateDays,
  restoreValueForMeasurement,
  roundToDigits,
  setAllDayWhenTimeAllDay,
  tfm1SystemNumbers,
  tfm2SystemNumbers,
  tfm3SystemNumbers,
  translateComponentType
};
