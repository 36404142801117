import { Button, Checkbox } from "antd";
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { CustomDndProvider, genId } from "@properate/ui";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslations } from "@properate/translations";
import useSWR from "swr";
import { ColumnsType } from "antd/lib/table/interface";
import { getUserNameWithEmail } from "@properate/common";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { AlertGroupMemberUI } from "@/features/alarms";
import { listUsers } from "@/eepApi";
import { GroupEditableRow } from "./GroupEditableRow";
import { EditableColumnTypes, GroupEditableCell } from "./GroupEditableCell";

type Props = {
  members: AlertGroupMemberUI[];
  setMembers: Dispatch<SetStateAction<AlertGroupMemberUI[]>>;
  validateMembers: () => boolean;
};

export const GroupDetailsTable = ({
  members,
  setMembers,
  validateMembers,
}: Props) => {
  const t = useTranslations();

  const { data: users } = useSWR("listUsers" || null, () => listUsers());

  const filteredUserEmails = useMemo(
    () =>
      users?.reduce(
        (acc, user) => {
          if (
            user.email &&
            !members.some((member) => member.defaultUserEmail === user.email)
          ) {
            acc.push({ label: user.name || "", value: user.email });
          }
          return acc;
        },
        [] as { label: string; value: string }[],
      ) || [],
    [members, users],
  );

  const [editingId, setEditingId] = useState("");

  const isEditing = (id: string) => id === editingId;

  const onDelete = (id: string) => {
    setMembers((members) => members.filter((member) => member.id !== id));
  };

  const onEditRow = (id: string) => {
    if (!validateMembers()) {
      return;
    }
    setEditingId(id);
  };

  const onFinishEdit = () => {
    if (!validateMembers()) {
      return;
    }
    setEditingId("");
  };

  const onChangeValue = (
    id: string,
    type: EditableColumnTypes,
    value: string | boolean | { value: string; label: string },
  ) => {
    setMembers((members) =>
      members.map((member) =>
        member.id === id ? { ...member, [type]: value } : member,
      ),
    );
  };

  const columns = [
    {
      key: "drag",
      width: 30,
      render: () => {
        return (
          <Button
            type="text"
            icon={<MenuOutlined className="w-4 h-4 mt-1" />}
          />
        );
      },
    },
    {
      title: t("alert-group.priority"),
      key: "priority",
      align: "center" as const,
      render: (_: string, __: AlertGroupMemberUI, index: number) => index + 1,
    },
    {
      title: t("alert-group.default-user-email"),
      key: "defaultUserEmail",
      render: (record: AlertGroupMemberUI) => {
        return getUserNameWithEmail({
          name: record.name,
          email: record.defaultUserEmail,
        });
      },
      onCell: (record: AlertGroupMemberUI) => ({
        record,
        type: "defaultUserEmail",
        editing: isEditing(record.id),
        onChangeValue: (value: { value: string; label: string }) => {
          onChangeValue(record.id, "defaultUserEmail", value.value);
          onChangeValue(record.id, "name", value.label);
        },
        filteredUserEmails,
      }),
    },
    {
      title: t("alert-group.notification-email"),
      dataIndex: "notificationEmail",
      key: "notificationEmail",
      onCell: (record: AlertGroupMemberUI) => ({
        record,
        type: "notificationEmail",
        editing: isEditing(record.id),
        onChangeValue: (value: string) => {
          onChangeValue(record.id, "notificationEmail", value);
        },
      }),
    },
    {
      title: t("alert-group.notify-by-email"),
      dataIndex: "notifyByEmail",
      key: "notifyByEmail",
      align: "center" as const,
      render: (notifyByEmail: boolean) => <Checkbox checked={notifyByEmail} />,
      onCell: (record: AlertGroupMemberUI) => ({
        record,
        type: "notifyByEmail",
        editing: isEditing(record.id),
        onChangeValue: (value: boolean) => {
          onChangeValue(record.id, "notifyByEmail", value);
        },
      }),
    },
    {
      title: t("alert-group.notification-phone"),
      dataIndex: "notificationPhone",
      key: "notificationPhone",
      onCell: (record: AlertGroupMemberUI) => ({
        record,
        type: "notificationPhone",
        editing: isEditing(record.id),
        onChangeValue: (value: string) => {
          onChangeValue(record.id, "notificationPhone", value);
        },
      }),
    },
    {
      title: t("alert-group.notify-by-phone"),
      dataIndex: "notifyByPhone",
      key: "notifyByPhone",
      align: "center" as const,
      render: (notifyByPhone: boolean) => <Checkbox checked={notifyByPhone} />,
      onCell: (record: AlertGroupMemberUI) => ({
        record,
        type: "notifyByPhone",
        editing: isEditing(record.id),
        onChangeValue: (value: boolean) => {
          onChangeValue(record.id, "notifyByPhone", value);
        },
      }),
    },
    {
      key: "actions",
      width: 100,
      render: ({ id }: AlertGroupMemberUI) => (
        <div className="flex gap-2 items-center justify-center">
          <Button
            aria-label={t("alert-group.delete")}
            icon={<DeleteOutlined />}
            disabled={members.length === 1}
            onClick={() => onDelete(id)}
            danger
          />
          {isEditing(id) ? (
            <Button
              onClick={() => onFinishEdit()}
              icon={<CheckOutlined />}
              type="primary"
              aria-label={t("alert-group.save")}
            />
          ) : (
            <Button
              onClick={() => onEditRow(id)}
              icon={<EditOutlined />}
              aria-label={t("alert-group.edit")}
            />
          )}
        </div>
      ),
    },
  ];

  const onUpdateOrder = (dragId: string, targetId: string) => {
    setMembers((members) => {
      const newMembers = [...members];
      const dragIndex = newMembers.findIndex((member) => member.id === dragId);
      const targetIndex = newMembers.findIndex(
        (member) => member.id === targetId,
      );
      const [removed] = newMembers.splice(dragIndex, 1);
      newMembers.splice(targetIndex, 0, removed);
      return newMembers;
    });
  };

  const onAddNew = () => {
    if (!validateMembers()) {
      return;
    }

    const newId = genId();

    setMembers((members) => [
      ...members,
      {
        id: newId,
        defaultUserEmail: "",
        notificationEmail: "",
        notificationPhone: "",
        notifyByEmail: false,
        notifyByPhone: false,
        name: "",
      },
    ]);

    setEditingId(newId);
  };

  return (
    <div>
      <div className="mb-2 flex justify-between items-center">
        <div>{t("alert-group.members")}</div>
        <Button onClick={onAddNew} icon={<PlusOutlined />} type="primary" ghost>
          {t("alert-group.add-user")}
        </Button>
      </div>
      <CustomDndProvider>
        <TableWithoutDefaultSort<AlertGroupMemberUI>
          components={{
            body: {
              row: GroupEditableRow,
              cell: GroupEditableCell,
            },
          }}
          dataSource={members}
          columns={columns as ColumnsType<AlertGroupMemberUI>}
          onRow={(record, index) => ({
            index,
            id: record.id,
            onUpdateOrder,
          })}
          rowKey="defaultUserEmail"
        />
      </CustomDndProvider>
    </div>
  );
};
