import { PageHeader } from "@ant-design/pro-layout";
import { App, Button, Form, Input, Space } from "antd";
import { useTranslations } from "@properate/translations";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import { useState } from "react";
import { parsePhoneNumber } from "libphonenumber-js";
import { CompactContent } from "@/components/CompactContent";
import { AlertGroupAPI, AlertGroupLoader } from "@/features/alarms";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { createGroup, deleteGroup, updateGroup } from "@/eepApi";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useAlertGroupNames } from "@/pages/alarmSystem/groupDetails/utils";
import { GroupDetailsTable } from "./GroupDetailsTable";

type FormValues = {
  name: string;
};

export const AlertGroup = () => {
  const t = useTranslations();
  const currentBuilding = useCurrentBuilding();
  useBuildingPageTitle(t("alert-group.title"));
  const { message } = App.useApp();

  const navigate = useNavigate();
  const pageData = useLoaderData() as AlertGroupLoader;

  const { groupNames, isLoadingGroups } = useAlertGroupNames(pageData.id);

  const [members, setMembers] = useState(pageData.members || []);

  const validateMembers = () => {
    return !members.some((member) => {
      if (!member.defaultUserEmail) {
        message.error(t("alert-group.please-select-user"));
        return true;
      }

      if (!member.notifyByPhone && !member.notifyByEmail) {
        message.error(t("alert-group.please-select-notification-method"));
        return true;
      }

      if (member.notificationPhone) {
        try {
          const valueParsedAsPhone = parsePhoneNumber(
            member.notificationPhone,
            "NO",
          );

          if (!valueParsedAsPhone.isValid()) {
            message.error(t("alert-group.invalid-phone-number"));
            return true;
          }
        } catch (e) {
          message.error(t("alert-group.invalid-phone-number"));
          return true;
        }
      }

      if (member.notifyByPhone && !member.notificationPhone) {
        message.error(t("alert-group.please-enter-phone-number"));
        return true;
      }

      return false;
    });
  };

  const onCancel = () => {
    navigate("../alertGroups");
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = async () => {
    if (!pageData.id) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteGroup(pageData.id);
    } catch (e) {
      setIsDeleting(false);
      message.error(t("alert-group.delete-failed"));
      console.error("error", e);
      return;
    }

    navigate("../alertGroups");
  };

  const [isUpdating, setIsUpdating] = useState(false);

  const onSubmit = async (values: FormValues) => {
    if (!validateMembers()) {
      return;
    }
    setIsUpdating(true);

    if (!currentBuilding.externalId) return;

    if (pageData.id) {
      try {
        const data = AlertGroupAPI.parse({
          id: pageData.id,
          name: values.name,
          buildingExternalId: currentBuilding.externalId,
          members,
        });

        await updateGroup(pageData.id, data);
      } catch (e) {
        setIsUpdating(false);
        message.error(t("alert-group.update-failed"));
        console.error("error", e);
        return;
      }
    }

    if (!pageData.id) {
      try {
        const data = AlertGroupAPI.parse({
          id: pageData.id,
          name: values.name,
          buildingExternalId: currentBuilding.externalId,
          members,
        });
        const group = await createGroup(data);
        navigate(`../alertGroups/${group.id}`);
      } catch (e) {
        setIsUpdating(false);
        message.error(t("alert-group.create-failed"));
        console.error("error", e);
        return;
      }
    }

    setIsUpdating(false);
  };

  const getTitle = () => {
    if (!pageData.id) {
      return t("alert-group.new-group");
    }
    return pageData.name;
  };

  return (
    <div>
      <PageHeader
        title={
          <Space>
            <Link to={`../alertGroups`}>{t("alert-groups.title")}</Link>
            <RightOutlined />
            {getTitle()}
          </Space>
        }
        extra={[
          <Button
            key="new"
            onClick={onCancel}
            aria-label={t("alert-group.cancel")}
          >
            {t("alert-group.cancel")}
          </Button>,
          ...(pageData.id
            ? [
                <Button
                  key="delete"
                  onClick={onDelete}
                  danger
                  ghost
                  loading={isDeleting}
                  aria-label={t("alert-group.delete")}
                >
                  {t("alert-group.delete")}
                </Button>,
                <Button
                  key="save"
                  ghost
                  type="primary"
                  aria-label={t("alert-group.save")}
                  htmlType="submit"
                  form="alert-group"
                  loading={isUpdating || isLoadingGroups}
                >
                  {t("alert-group.save")}
                </Button>,
              ]
            : [
                <Button
                  key="create"
                  type="primary"
                  ghost
                  htmlType="submit"
                  form="alert-group"
                  aria-label={t("alert-group.create")}
                  loading={isUpdating || isLoadingGroups}
                >
                  {t("alert-group.create")}
                </Button>,
              ]),
        ]}
      />
      <CompactContent>
        <Form<FormValues>
          name={"alert-group"}
          layout="vertical"
          initialValues={{
            name: pageData.name,
          }}
          onFinish={onSubmit}
        >
          <Form.Item
            label={t("alert-group.name")}
            name="name"
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  if (groupNames.includes(value)) {
                    return Promise.reject(t("alert-group.name-exists"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input required />
          </Form.Item>
          <GroupDetailsTable
            members={members}
            setMembers={setMembers}
            validateMembers={validateMembers}
          />
        </Form>
      </CompactContent>
    </div>
  );
};
